<template>
  <div class="lg:mt-28"></div>
  <SectionTitle name="NEW ALBUM OUT !" />
  <Highlight :highlight="highlight" />
  <SectionTitle name="ALL RELEASES" />
  <SubTitle name="ALBUMS" />
  <AlbumList />
  <SubTitle name="SINGLES & EPS" />
  <SingleList />
</template>
<script>
import SectionTitle from '../common/SectionTitle'
import Highlight from '../common/Highlight'
import SubTitle from '../common/SubTitle'
import AlbumList from './AlbumList'
import SingleList from './SingleList'
import axios from 'axios'

export default {
  components: { SubTitle, AlbumList, SingleList, SectionTitle, Highlight },
  name: 'Music',
  data: function () {
    return { highlight: {} }
  },
  mounted: function() {
    axios
      .get('/files/highlight.json')
      .then(response => { 
        this.highlight = response.data;
    })
  }
}
</script>