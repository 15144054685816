<template>
  <Hello />
</template>

<script>
import Hello from './Hello'

export default {
  name: 'App',
  components: {
    Hello,
  }
}
</script>
