<template>
  <ShortcutPage :release="release"></ShortcutPage>
</template>
<script>
import ShortcutPage from './ShortcutPage'

export default {
  name: 'Libellula',
  data: function() {
    return { release: null }
  },
  components: {
    ShortcutPage,
  },
  mounted: function () {
    this.release = {
      img: '/img/single-libellula.jpg',
      name: 'Libellula',
      type: 'single',
      links: [
        {
          service: 'bandcamp',
          url: 'https://grizzlycogs.bandcamp.com/track/libellula',
          btnLabel: 'Listen'
        },
        {
          service: 'spotify',
          url: 'https://open.spotify.com/intl-fr/album/3UnlQccCuFEi4lmqUyqpRz?si=PBKyiZ5IRu2kTXhPkZHqtg',
          btnLabel: 'Listen'
        },
        {
          service: 'youtube',
          url: 'https://www.youtube.com/watch?v=yAOqwdsMMWY&list=OLAK5uy_m2buNRAejn8gfrc5NI-7TtNpepv4vmMq4',
          btnLabel: 'Listen'
        },
        {
          service: 'deezer',
          url: 'https://deezer.page.link/v3AUGy5Ju3Shbnhz5',
          btnLabel: 'Listen'
        },
        // {
        //   service: 'apple',
        //   url: 'https://music.apple.com/fr/album/baluchon/1703400464',
        //   btnLabel: 'Listen'
        // },
        // {
        //   service: 'itunes',
        //   url: 'https://music.apple.com/fr/album/baluchon/1703400464',
        //   btnLabel: 'Buy'
        // },
        // {
        //   service: 'amazon',
        //   url: 'https://www.amazon.com/music/player/albums/B0CG3S2YLS',
        //   btnLabel: 'Buy'
        // },
        {
          service: 'tidal',
          url: 'https://tidal.com/browse/album/341751812',
          btnLabel: 'Listen'
        }
      ]
    }
  }
}
</script>