<template>
  <ShortcutPage :release="release"></ShortcutPage>
</template>
<script>
import ShortcutPage from './ShortcutPage'

export default {
  name: 'Wakey',
  data: function() {
    return { release: null }
  },
  components: {
    ShortcutPage,
  },
  mounted: function () {
    this.release = {
      img: '/img/single-wakey.jpg',
      name: 'wakey wakey lil flowers',
      type: 'single',
      links: [
        {
          service: 'bandcamp',
          url: 'https://grizzlycogs.bandcamp.com/track/wakey-wakey-lil-flowers-feat-3xblast',
          btnLabel: 'Listen'
        },
        {
          service: 'youtube',
          url: 'https://www.youtube.com/watch?v=r2tuUScvTjs',
          btnLabel: 'Watch'
        },
        {
          service: 'spotify',
          url: 'https://open.spotify.com/album/7iyeYex2GeKgfG6Bzh4LmG?si=3orgWeLGTjGg97Fj87O8lg',
          btnLabel: 'Listen'
        },
        {
          service: 'deezer',
          url: 'https://deezer.page.link/TXeX6gLWZkudHwho6',
          btnLabel: 'Listen'
        },
        {
          service: 'apple',
          url: 'https://music.apple.com/fr/album/wakey-wakey-lil-flowers-feat-3xblast-single/1630415728',
          btnLabel: 'Listen'
        },
        {
          service: 'itunes',
          url: 'https://music.apple.com/fr/album/wakey-wakey-lil-flowers-feat-3xblast-single/1630415728',
          btnLabel: 'Buy'
        },
        {
          service: 'amazon',
          url: 'https://amazon.fr/music/player/albums/B0B4FYHH4B?marketplaceId=A13V1IB3VIYZZH',
          btnLabel: 'Buy'
        },
        {
          service: 'tidal',
          url: 'https://tidal.com/browse/album/234137010',
          btnLabel: 'Listen'
        }
      ]
    }
  }
}
</script>