<template>
  <div class="fixed bg-black bg-opacity-40 z-30 w-full h-full" v-show="opened" v-on:click="close()">
    
  </div>
  <div class="fixed z-40 bg-green-400 w-1/2 top-1/4 left-1/4 w-1/2 p-2 rounded"  v-show="opened" v-if="popupdata">
    <div class="absolute z-50 text-white top-0 right-0 my-2 mx-4">
      <i v-on:click="close()" class="fas fa-times text-white text-xl cursor-pointer"></i>
    </div>
    <div class="absolute z-50 ml-2 rounded bg-green-600 text-white font-medium pr-2 pl-2">
      {{ type }}
    </div>    

    <!-- TITLE -->
    <div class="text-white">
      <span class="text-xl">{{ popupdata.name.toUpperCase() }} ({{ popupdata.year }})</span><br />
    </div>

    <!-- PINS -->
    <div class="flex p-2 justify-center">
      <div class="ml-1 pr-2 pl-2 rounded-full bg-yellow-500 text-white" v-if="popupdata.work && popupdata.work.includes('OST')">
        soundtrack
      </div>
      <div class="ml-1 pr-2 pl-2 rounded-full bg-purple-500 text-white" v-if="popupdata.work && popupdata.work.includes('DEV')">
        dev
      </div>
      <div class="ml-1 pr-2 pl-2 rounded-full bg-purple-500 text-white" v-if="popupdata.type && popupdata.type == 'FEAT'">
        featuring
      </div>
      <div class="ml-1 pr-2 pl-2 rounded-full bg-purple-500 text-white" v-if="popupdata.type && popupdata.type == 'REMIX'">
        remix
      </div>
      <div class="ml-1 pr-2 pl-2 rounded-full bg-yellow-500 text-white" v-if="popupdata.type && popupdata.type == 'COMP'">
        compilation
      </div>
      <div class="ml-1 pr-2 pl-2 rounded-full bg-blue-500 text-white" v-if="popupdata.type && popupdata.type == 'EP'">
        EP
      </div>
      <div class="ml-1 pr-2 pl-2 rounded-full bg-blue-500 text-white" v-if="popupdata.type && popupdata.type == 'Single'">
        single
      </div>
    </div>

    <!-- INFOS -->
    <div class="flex m-2">
      <div class="m-whalf rounded">
        <img :src="getCover()" class="rounded"/>
      </div>
      <div class="w-1/2 text-sm text-green-700 text-justify text-white px-2">
        <span v-html="getDescription()"></span>
        <div v-if="type== 'GAME'" >
          <br />
          <h1 class="text-lg font-semibold">★ Credit</h1>
          <span v-html="popupdata.credits"></span>
        </div>
      </div>
    </div>



    <!-- BOTTOM BUTTONS -->
      <div class="flex justify-center">
        <router-link :to="getInfoUrl()" v-if="type != 'SINGLE'">
          <div v-on:click="close()" class="w-32 my-4 bg-green-600 rounded text-white font-medium px-2 py-1 cursor-pointer">
            <i class="fas fa-info-circle px-2"></i> MORE ...
          </div>
        </router-link>
        <a :href="getPlayUrl()" v-if="type == 'GAME'">
          <div class="ml-4 w-32 my-4 bg-green-600 rounded text-white font-medium px-2 py-1 cursor-pointer">
            <i class="fas fa-gamepad px-2"></i> PLAY!
          </div>
        </a>
        <a :href="popupdata.bandcampLink || popupdata.link" v-if="type != 'GAME'"> 
          <div class="ml-4 w-32 my-4 bg-green-600 rounded text-white font-medium px-2 py-1 cursor-pointer">
            <i class="fas fa-headphones-alt px-2"></i> LISTEN!
          </div>
        </a>
      </div>

  </div>
</template>
<script>
export default {
  name: 'Popup',
  data: function() {
    return { opened: false, popupdata: null, type: '', isRRJP: false }
  },
  methods: {
    open: function() {
      this.opened = true;
    },
    close: function() {
      this.opened = false;
    },
    getDescription: function() {
      if (this.popupdata.infos)
        return this.popupdata.infos;
      return '';
    },
    getCover: function() {
      if (this.type == 'GAME')
        return this.popupdata.galleryDir + '/' + this.popupdata.galleryImages[0];
      return '/img/' + this.popupdata.artcover;
    },
    getInfoUrl: function () {
      if (this.type == 'GAME')
        return '/' + this.type.toLowerCase() + '/' + this.popupdata.name;
      else if (this.type == 'ALBUM' && !this.isRRJP)
        return '/release/album/' + this.popupdata.name;
      else if (this.type == 'ALBUM' && this.isRRJP)
        return '/release/rrjp/' + this.popupdata.name;
      else 
        return '';
    },
    getPlayUrl: function () {
      return this.popupdata.itchioLink ? this.popupdata.itchioLink : this.popupdata.androidLink;
    }
  },
  mounted: function () {
    this.$store.subscribe((mutation) => {
      //OPEN
      if (mutation.type == 'open') {
        this.open();
        this.popupdata = this.$store.state.popupdata;
        this.type = this.$store.state.type;
        this.isRRJP = this.$store.state.isRRJP;
      }
    });
  }
}
</script>
<style scoped>
  .m-whalf {
    max-width: 50%;
  }
</style>