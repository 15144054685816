<template>
  <div class="hidden lg:flex relative flex justify-center bg-hl" v-bind:style="[!highlight.gameUrl ? {'backgroundImage': 'url(' + highlight.imgUrl + ')'} : {'backgroundImage': 'none'} ]">
    <div class="absolute blur w-full h-full"></div>
    <div class="relative highlight rounded-lg w-full md:w-1/2 cursor-pointer my-4 " @mouseenter="hover = true" @mouseleave="hover = false"
      v-bind:style="{ backgroundImage: 'url(' + highlight.imgUrl + ')' }" v-on:click="playPause()">

      <!-- TEXT DIV -->
      <div class="absolute z-20 left-0 bottom-0 rounded-b-lg bg-green-400 w-full text-white p-4 text-left flex cursor-default shadow-lg">
        
        <!-- AWARDS -->
        <div class="absolute reward-grid grid lg:grid-cols-3 left-0">
          <div class="reward rounded-lg px-2 mx-2 shadow-2xl" v-for="award in highlight.awards" :key="award.cat" >
            <div class="text-center pt-2 top-0 z-10 text-white">
              <div class="text-sm text-yellow-800 font-bold text-center">
                <i v-if="award.title != 'Frontpaged'" class="fas fa-trophy"></i>
                <i v-if="award.title == 'Frontpaged'" class="fas fa-star"></i> {{ award.title}}
              </div>
              <div class="text-xs text-yellow-600">{{ award.award }}</div>
              <div class="text-xs text-yellow-600">{{ award.cat }}</div>
            </div>
          </div>
        </div>
        <div class="lg:w-3/4 w-full">
          <p class="text-3xl font-semibold">{{ highlight.title }}</p>
          <p class="mt-4 justify" v-html="highlight.info"></p>
        </div>
        <div class="p-4 hidden lg:block">
          <p class="text-green-100 pb-2"><i class="fas fa-link"></i> Quick links:</p>
          <p v-for="link in highlight.links" :key="link.url" class="font-semibold">
            <a :href="link.url">
              <p v-if="link.type == 'bandcamp'"><i class="fab fa-bandcamp"></i> Bandcamp</p>
              <p v-if="link.type == 'spotify'"><i class="fab fa-spotify"></i> Spotify</p>
              <p v-if="link.type == 'youtube'"><i class="fab fa-youtube"></i> Youtube</p>
              <p v-if="link.type == 'itchio'"><i class="fab fa-itch-io"></i> Itch.io</p>
              <p v-if="link.type == 'newgrounds'">Newgrounds</p>
            </a>
          </p>
        </div>
        <!-- MINI MUSIC PLAYER -->
        <div class="absolute z-50 play-btn text-red-900 bg-green-400 py-4 px-4 rounded-full cursor-pointer"
          v-bind:class="{'text-green-100': hover}">
          <i class="far fa-play-circle text-5xl" v-if="!playing"></i>
          <i class="far fa-pause-circle text-5xl" v-if="playing"></i>
        </div>
        <div class="absolute z-50 game-btn text-white bg-green-400 py-4 px-4 rounded-full cursor-pointer"
          v-if="highlight.gameUrl">
          <a :href="highlight.gameUrl">
            <i class="fas fa-gamepad text-4xl"></i>
          </a>
        </div>
        <div class="hidden lg:block absolute z-50 info-player rounded bg-green-400 px-2 py-1" v-if="playing">
          <i class="fas fa-music"></i> You are listening to "{{ highlight.audio.title }}" taken from {{ highlight.title }}
          <span v-if="highlight.gameUrl"> soundtrack</span>...
        </div>
      </div>
    </div>
  </div>

  <!-- MOBILE -->
  <div class="flex lg:hidden justify-center">
    <div class="relative mx-8 mt-2 highlight-mobile w-full rounded-t-lg" v-bind:style="{ backgroundImage: 'url(' + highlight.imgUrl + ')' }">
      <div class="absolute z-10 play-btn-mobile text-green-400">
        <a v-if="highlight.gameUrl" :href="highlight.gameUrl"><i class="far fa-play-circle text-5xl"></i></a>
        <a v-if="!highlight.gameUrl" :href="getBandcampUrl()"><i class="far fa-play-circle text-5xl"></i></a>
      </div>
    </div>
  </div>
  <div class="block lg:hidden mx-8 text-left mb-4 p-2 bg-green-400 rounded-b-lg">
    <p class="text-xl font-semibold">{{ highlight.title }}</p>
    <p class="text-xs justify" v-html="highlight.info"></p>
  </div>
</template>
<script>
export default {
  name: 'Highlight',
  props: ['highlight'],
  data: function () {
    return { hover: false, playing: false, audio: null }
  },
  methods: {
    getBandcampUrl: function() {
      return this.highlight && this.highlight.links ? this.highlight.links[0].url : '';
    },
    playPause: function() {
      if (!this.playing) {
        this.audio = new Audio(this.highlight.audio.url);
        this.audio.play();
      } else {
        this.audio.pause();
      }
      this.playing = !this.playing;
    }
  }
}
</script>
<style scoped>
  .info-player {
    top: -22px;
    left: 0px;
  }

  .game-btn {
    top: -32px;
    right: 108px;
  }

  .play-btn {
    top: -40px;
    right:16px;
  }

  .highlight-mobile {
    height: 15rem;
    background-size: cover;
  }

  .play-btn-mobile {
    top: 50%;
    width: 100%;
  }

  .highlight {
    background-image: url("/img/highlight/confettis.jpg");
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    background-size: cover;
    background-position: 50% 10%;
    height: 40rem;
    transition: transform 100ms ease-in-out;
  }

  .highlight:hover {
    transform: scale(1.02);
    background-color: rgb(110, 231, 183);
  }

  .bg-hl {
    background-size: cover;
    background-position: 0% 90%;
  }

  .blur {
    backdrop-filter: blur(10px);
  }

  .reward-grid {
    top: -70px;
  }

  .reward {
    background-color: gold;
  }

  .legend {
    color: goldenrod;
  }

</style>