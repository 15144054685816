<template>
  <ShortcutPage :release="release"></ShortcutPage>
</template>
<script>
import ShortcutPage from './ShortcutPage'

export default {
  name: 'TenYears',
  data: function() {
    return { release: null }
  },
  components: {
    ShortcutPage,
  },
  mounted: function () {
    this.release = {
      img: '/img/single-10years.jpeg',
      name: '10 years of cogs',
      type: 'single',
      links: [
        {
          service: 'bandcamp',
          url: 'https://grizzlycogs.bandcamp.com/track/10-years-of-cogs',
          btnLabel: 'Listen'
        },
        {
          service: 'spotify',
          url: 'https://open.spotify.com/intl-fr/album/0shwaWmourpVWuAVKAwD4x',
          btnLabel: 'Listen'
        },
        {
          service: 'youtube',
          url: 'https://www.youtube.com/watch?v=v9_Q_DwOcxo',
          btnLabel: 'Listen'
        },
        {
          service: 'deezer',
          url: 'https://www.deezer.com/en/album/664970041',
          btnLabel: 'Listen'
        },
        {
          service: 'apple',
          url: 'https://music.apple.com/fr/album/10-years-of-cogs-single/1777586584',
          btnLabel: 'Listen'
        },
        {
          service: 'itunes',
          url: 'https://music.apple.com/fr/album/10-years-of-cogs-single/1777586584',
          btnLabel: 'Buy'
        },
        {
          service: 'amazon',
          url: 'https://www.amazon.fr/music/player/albums/B0DLPZZC6Q',
          btnLabel: 'Buy'
        },
        {
          service: 'tidal',
          url: 'https://tidal.com/browse/album/397136384',
          btnLabel: 'Listen'
        }
      ]
    }
  }
}
</script>