<template>
  <div class="flex py-1 px-2">
    <div class="w-2/3 text-center">
      <img v-if="link.service == 'spotify'" src="/img/shortcut-logo/spotify.png" class="pt-2 pl-2 w-3/4" />
      <img v-if="link.service == 'bandcamp'" src="/img/shortcut-logo/bandcamp.png" class="pt-3 w-3/4"/>
      <img v-if="link.service == 'deezer'" src="/img/shortcut-logo/deezer.png" class="pt-3 pl-3 w-3/4"/>
      <img v-if="link.service == 'apple'" src="/img/shortcut-logo/apple.svg" class="pt-2 pl-2 w-3/4">
      <img v-if="link.service == 'itunes'" src="/img/shortcut-logo/itunes.svg" class="pt-2 pl-2 w-3/4">
      <img v-if="link.service == 'youtube'" src="/img/shortcut-logo/youtube.png" class="pt-3 pl-2 w-3/4">
      <img v-if="link.service == 'amazon'" src="/img/shortcut-logo/amazon.png" class="pt-4 pl-3 w-3/4">
      <img v-if="link.service == 'tidal'" src="/img/shortcut-logo/tidal.svg" class="pl-3 w-3/4">
    </div>
    <div class="w-1/3"></div>
    <div class="w-1/3 pt-2">
      <a :href="link.url">
        <div class="cursor-pointer rounded w-full p-2 my-1 bg-green-400 text-white">
          {{ link.btnLabel }}
        </div>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ShortcutLink',
  props: ['link'],
}
</script>
<style scoped>
  .width-lowered {
    width:80%;
  }
</style>