<template>
  <ShortcutPage :release="release"></ShortcutPage>
</template>
<script>
import ShortcutPage from './ShortcutPage'

export default {
  name: 'Baluchon',
  data: function() {
    return { release: null }
  },
  components: {
    ShortcutPage,
  },
  mounted: function () {
    this.release = {
      img: '/img/album-baluchon.jpg',
      name: 'Baluchon',
      type: 'album',
      links: [
        {
          service: 'bandcamp',
          url: 'https://grizzlycogs.bandcamp.com/album/baluchon',
          btnLabel: 'Listen'
        },
        {
          service: 'spotify',
          url: 'https://open.spotify.com/intl-fr/album/0Qhx5PJoBTVysFEYdAqhKt?si=vudLmVZXRQCekjACplSLhQ',
          btnLabel: 'Listen'
        },
        {
          service: 'youtube',
          url: 'https://www.youtube.com/watch?v=fcT5BaN-Eeg&list=OLAK5uy_nQdl7kzqiE-_y-SX2RY7NOFML586FHxbs',
          btnLabel: 'Listen'
        },
        {
          service: 'deezer',
          url: 'https://deezer.page.link/KaGwiB9KRSeyDBC39',
          btnLabel: 'Listen'
        },
        {
          service: 'apple',
          url: 'https://music.apple.com/fr/album/baluchon/1703400464',
          btnLabel: 'Listen'
        },
        {
          service: 'itunes',
          url: 'https://music.apple.com/fr/album/baluchon/1703400464',
          btnLabel: 'Buy'
        },
        {
          service: 'amazon',
          url: 'https://www.amazon.com/music/player/albums/B0CG3S2YLS',
          btnLabel: 'Buy'
        },
        {
          service: 'tidal',
          url: 'https://tidal.com/browse/album/311819741',
          btnLabel: 'Listen'
        }
      ]
    }
  }
}
</script>