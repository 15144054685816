<template>
    <div class="m-2 relative shadow-xl rounded" @mouseenter="hover = true" @mouseleave="hover = false">
      <router-link :to="'/release/single/' + singleData.name">
        <img class="w-96 rounded" :src="'/img/' + singleData.artcover" v-on:click="show = true" />
      </router-link>
      <div class="absolute flex bottom-0 right-0 m-2 text-xs xl:text-sm">
        <div class="hidden xl:block pr-2 pl-2 rounded-full bg-yellow-500 text-white bg-opacity-90" v-if="singleData.type == 'COMP'">
          comp
        </div>
        <div class="hidden xl:block pr-2 pl-2 rounded-full bg-blue-500 text-white bg-opacity-90" v-if="singleData.type == 'EP'">
          EP
        </div>
        <div class="hidden xl:block pr-2 pl-2 rounded-full bg-blue-500 text-white bg-opacity-90" v-if="singleData.type == 'Single'">
          single
        </div>
        <div class="hidden xl:block pr-2 pl-2 rounded-full bg-purple-500 text-white bg-opacity-90" v-if="singleData.type == 'REMIX'">
          remix
        </div>
        <div class="hidden xl:block pr-2 pl-2 rounded-full bg-purple-500 text-white bg-opacity-90" v-if="singleData.type == 'FEAT'">
          featuring
        </div>
        <div class="ml-1 pr-2 pl-2 rounded-full bg-red-500 text-white bg-opacity-90">
          {{ singleData.year }}
        </div>
      </div>
      <transition name="fade">
        <div v-if="hover" class="absolute top-0 left-0 p-2 w-full h-full bg-green-400 bg-opacity-90 text-white rounded hidden lg:block">
          <div class="absolute bottom-2 text-3xl w-full text-center">
            <a :href="singleData.link" target="_blank">
              <i class="fas fa-headphones-alt mr-8 cursor-pointer"></i>
            </a>
            <i class="fas fa-info-circle cursor-pointer" v-on:click="open()"></i>
          </div>
          <p class="tracking-wider text-xl text-white">{{ singleData.name }}</p>
        </div>
      </transition>
    </div>

</template>
<script>
export default {
  name: 'Single',
  props: ['singleData'],
  data: function() {
    return { hover: false }
  },
  methods: {
    open: function () {
      var payload = {
        item: this.singleData,
        type: 'SINGLE'
      };
      this.$store.commit('open', payload);
    }
  }
}
</script>
<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }

</style>