<template>
  <ShortcutPage :release="release"></ShortcutPage>
</template>
<script>
import ShortcutPage from './ShortcutPage'

export default {
  name: 'WhenWeWereFree',
  data: function() {
    return { release: null }
  },
  components: {
    ShortcutPage,
  },
  mounted: function () {
    this.release = {
      img: '/img/album-hibernation.jpg',
      name: 'Hibernation',
      type: 'album',
      links: [
        {
          service: 'bandcamp',
          url: 'https://grizzlycogs.bandcamp.com/album/hibernation',
          btnLabel: 'Listen'
        },
        {
          service: 'spotify',
          url: 'https://open.spotify.com/album/5zes49L0jXrml8waEUclNn?si=Zn4an9ONSvGKt6V3-0u-_Q',
          btnLabel: 'Listen'
        },
        {
          service: 'youtube',
          url: 'https://www.youtube.com/watch?v=xsHCN7qoj3A&list=OLAK5uy_lzR5P9f8oyHKzJ6fflwRGmLWGwV43B52M',
          btnLabel: 'Listen'
        },
        {
          service: 'deezer',
          url: 'https://deezer.page.link/qumcvsFLiSahRyWK7',
          btnLabel: 'Listen'
        },
        {
          service: 'apple',
          url: 'https://music.apple.com/fr/album/hibernation/1598854365',
          btnLabel: 'Listen'
        },
        {
          service: 'itunes',
          url: 'https://music.apple.com/fr/album/hibernation/1598854365',
          btnLabel: 'Buy'
        },
        {
          service: 'amazon',
          url: 'https://www.amazon.fr/Hibernation-Grizzly-Cogs/dp/B09N3N6NQX',
          btnLabel: 'Buy'
        },
        {
          service: 'tidal',
          url: 'tidal.com/browse/album/207912295',
          btnLabel: 'Listen'
        }
      ]
    }
    // AMAZON
    // TIDAL
    // P
  }
}
</script>