<template>
  <div class="lg:mt-28"></div>
  <SectionTitle id="shows" name="GRIZZLY COGS LIVE" />
  <div class="mx-16">
    <Carousel timer="3000" :gallery="gallery()" />
  </div>
  <SectionTitle id="shows" name="PAST SHOWS" />
  <p class="my-4">
    From time to time, I play live at shows across Europe. I play as a Solo act, or with my band Run! Run! Jump! Punch!. If you want to 
    learn more about my band, <a href="/rrjp" class="text-green-400 font-bold">click here</a>
  </p>
  <ShowList />
</template>
<script>
import SectionTitle from '../common/SectionTitle'
import ShowList from './ShowList'
import Carousel from './../common/Carousel'

export default {
  name: 'GrizzlyCogsLive',
  components: { SectionTitle, ShowList, Carousel },
  methods: {
    gallery: function() {
      return [
        { id: 0, src: "img/hello2.jpeg", credit: "Loïse Doyen", link: "https://loisedoyen.com/" },
        { id: 1, src: "img/hello.jpg", credit: "Loïse Doyen", link: "https://loisedoyen.com/" },
        { id: 2, src: "img/hello3.jpg", credit: "Chiptography", link: "https://chiptography.com/" },
      ]
    }
  },
}
</script>