<template>
  <div class="hidden xl:block" v-if="!hidden">
    <nav class="flex justify-between flex-row bg-green-400 shadow-xl fixed top-0 z-30 w-full">
      <div class="ml-2 w-48 py-3 mr-4">
        <a href="/"><img alt="Grizzly Cogs" src="../../assets/logo.png"></a>
      </div>
      <div class="flex py-4 text-xl">
        <MenuItem item-title="Music" link="/music" :newtab="false" />
        <div class="vl mt-4 mb-4"></div>
        <MenuItem item-title="Games" link="/games" :newtab="false"/>
        <div class="vl mt-4 mb-4"></div>
        <MenuItem item-title="Listen" link="http://grizzlycogs.bandcamp.com" :newtab="true"/>
        <div class="vl mt-4 mb-4"></div>
        <MenuItem item-title="Live" link="/live" :newtab="false" />
        <div class="vl mt-4 mb-4"></div>
        <MenuItem item-title="Merch" link="http://grizzlycogs.bandcamp.com/merch" :newtab="true" />
        <div class="vl mt-4 mb-4"></div>
        <MenuItem item-title="Tutorials" link="/tuto" :newtab="false" />
        <div class="vl mt-4 mb-4"></div>
        <MenuItem item-title="Contact / About" link="/contact" :newtab="false" />
        <div class="vl mt-4 mb-4"></div>
        <MiniMenuItem icon="fab fa-bandcamp" link="http://grizzlycogs.bandcamp.com" />
        <MiniMenuItem icon="fab fa-spotify" link="https://open.spotify.com/artist/6DYBO57C80qqR0VvCWLwCl?si=n13UY0f_T6OFQw-lRJm5Iw" />
        <MiniMenuItem icon="fab fa-instagram" link="https://www.instagram.com/grizzlycogs/" />
        <MiniMenuItem icon="fab fa-twitter" link="https://twitter.com/GrizzlyCogs" />
        <MiniMenuItem icon="fab fa-facebook" link="https://www.facebook.com/GrizzlyCogs/" />
      </div>
      
    </nav>
  </div>

  <!-- MOBILE -->
  
  <!-- open button -->
  <div class="xl:hidden fixed top-2 right-2 bg-green-400 px-2 py-1 rounded text-lg text-white z-30 cursor-pointer" v-if="!menuOpened && !hidden">
    <i class="fas fa-bars" v-on:click="menuOpened = true"></i>
  </div>
  <!-- close button -->
  <div class="xl:hidden fixed top-2 right-2 text-2xl text-green-400 z-50 cursor-pointer" v-if="menuOpened">
    <i class="fas fa-times" v-on:click="menuOpened = false"></i>
  </div>

  <div class="xl:hidden bg-white w-full h-full fixed bg-opacity-90 z-30 text-left pl-8 py-2 leading-10" v-if="menuOpened">
    <div class="text-center text-2xl text-green-400">Menu</div>
    <a href="/music" class="text-green-400 text-2xl my-2" v-on:click="menuOpened=false">Music</a><br />    
    <a href="/games" class="text-green-400 text-2xl my-2" v-on:click="menuOpened=false">Games</a><br />
    <a href="/http://grizzlycogs.bandcamp.com" class="text-green-400 text-2xl my-2" v-on:click="menuOpened=false">Listen</a><br />
    <a href="/live" class="text-green-400 text-2xl my-2" v-on:click="menuOpened=false">Live</a><br />
    <a href="http://grizzlycogs.bandcamp.com/merch" class="text-green-400 text-2xl my-2" v-on:click="menuOpened=false">Merch</a><br />
    <a href="/tuto" class="text-green-400 text-2xl my-2" v-on:click="menuOpened=false">Tutorials</a><br />
    <a href="/contact" class="text-green-400 text-2xl my-2" v-on:click="menuOpened=false">Contact / About</a><br />
    <a href="http://grizzlycogs.bandcamp.com" class="text-green-400 text-2xl my-2 mr-2"><i class="fab fa-bandcamp"></i></a>
    <a href="https://open.spotify.com/artist/6DYBO57C80qqR0VvCWLwCl?si=n13UY0f_T6OFQw-lRJm5Iw" class="text-green-400 text-2xl m-2"><i class="fab fa-spotify"></i></a>
    <a href="https://www.instagram.com/grizzlycogs/" class="text-green-400 text-2xl m-2"><i class="fab fa-instagram"></i></a>
    <a href="https://twitter.com/GrizzlyCogs" class="text-green-400 text-2xl m-2"><i class="fab fa-twitter"></i></a>
    <a href="https://www.facebook.com/GrizzlyCogs/" class="text-green-400  text-2xl m-2"><i class="fab fa-facebook"></i></a>
  </div>
</template>

<script>
import MenuItem from './MenuItem'
import MiniMenuItem from './MiniMenuItem'

export default {
  name: 'Menu',
  components: {
    MenuItem, MiniMenuItem
  },
  data: function() {
    return {menuOpened: false, hidden: false}
  },
  mounted: function() {
    this.$store.subscribe((mutation) => {
      //OPEN
      if (mutation.type == 'hideMenu') {
        this.hidden = true;
      } else {
        this.hidden = false;
      }
    });
  }
}
</script>
<style scoped>
  .vl {
    border-left: 1px solid white;
  }
</style>