<template>
  <div class="lg:mt-28"></div>
  <SectionTitle name="GAMES & SOUNDTRACKS" />
  <GameList />
</template>
<script>
import SectionTitle from '../common/SectionTitle'
import GameList from '../game/GameList'

export default {
  name: "Games",
  data: function () {
    return { soundtrackHighlights: [], gameHighlights: [] }
  },
  components: { SectionTitle, GameList }
}
</script>