<template>
  <ShortcutPage :release="release"></ShortcutPage>
</template>
<script>
import ShortcutPage from './ShortcutPage'

export default {
  name: 'WhenWeWereFree',
  data: function() {
    return { release: null }
  },
  components: {
    ShortcutPage,
  },
  mounted: function () {
    this.release = {
      img: '/img/single-wwwf.jpg',
      name: 'When we were free',
      type: 'single',
      links: [
        {
          service: 'bandcamp',
          url: 'https://grizzlycogs.bandcamp.com',
          btnLabel: 'Listen'
        },
        {
          service: 'spotify',
          url: 'https://open.spotify.com/album/780TYge5PgcrTj1eWi93wd?si=82f9e79701554918',
          btnLabel: 'Listen'
        },
        {
          service: 'youtube',
          url: 'https://www.youtube.com/watch?v=-D-Wp03EYdg',
          btnLabel: 'Listen'
        },
        {
          service: 'deezer',
          url: 'https://www.deezer.com/album/272275182?utm_campaign=clipboard-generic&utm_source=user_sharing&utm_medium=desktop&utm_content=album-272275182&deferredFl=1',
          btnLabel: 'Listen'
        },
        {
          service: 'apple',
          url: 'https://music.apple.com/fr/album/when-we-were-free-single/1595056601',
          btnLabel: 'Listen'
        },
        {
          service: 'itunes',
          url: 'https://music.apple.com/fr/album/when-we-were-free-single/1595056601',
          btnLabel: 'Buy'
        },
        {
          service: 'amazon',
          url: 'https://music.amazon.fr/albums/B09LRDVNTJ?marketplaceId=A13V1IB3VIYZZH&musicTerritory=FR&ref=dm_sh_wZh5gKGw6mb1t1YdMWxGRLfmG',
          btnLabel: 'Buy'
        },
        {
          service: 'tidal',
          url: 'https://www.tidal.com/browse/album/204894352',
          btnLabel: 'Listen'
        }
      ]
    }
    // AMAZON
    // TIDAL
    // P
  }
}
</script>