<template>
  <div class="fixed top-48 bg-green-400 text-white p-4 text-left invisible lg:visible">
    <b>Sommaire</b><br />
    <a href="#1">1. Le fonctionnement de GB Studio</a><br />
    <a href="#2">2. Créer une partie rythmique</a><br />
    <a href="#3">3. Créer une mélodie</a><br />
    <a href="#4">4. Créer une basse</a><br />
    <a href="#5">5. Créer un accompagnement</a><br />
    <a href="#6">6. Pour en apprendre plus</a>
  </div>
  <div class="">
    <div class="lg:mt-24"></div>
    <p class="lg:mx-96 mx-4 text-yellow-800 font-bold mb-4 text-left">
      <a href="/tuto">
        <i class="fa-solid fa-arrow-left"></i> Retour aux tutoriels
      </a>
    </p>
    <SectionTitle name="Introduction à la chiptune avec GB Studio" />
    <div class="lg:mx-96 mx-4 text-left">

      <!-- PART 1 - GB STUDIO -->
      <p id="1" class="text-green-400 font-bold text-xl mb-4">1. Le fonctionnement de GB Studio</p>
      <SubTitle name="1.1 Le fonctionnement de GB Studio" />
      <p class="my-4 text-justify">
        GB Studio est un logiciel permettant de réaliser des jeux vidéo pour Game Boy et Game Boy Color. 
        Nous n’allons nous intéresser ici qu’à la partie “Musique”.
        Une fois GB Studio ouvert, cliquez sur le bouton <b>Monde de jeu</b> (au haut à gauche) et sélectionnez <b>Musique</b>. 
        Vous arrivez sur l’interface de création musicale pour Game Boy, comme représenté ci-dessous.
      </p>
      <center><img src="/tuto/chiptune1.png" /></center>

      <SubTitle name="1.2 Les 4 canaux" />
      <p class="my-4  text-justify">
        La puce de la game boy étant limitée, il ne peut y avoir que 4 instruments qui jouent simultanément. 
        Il y a donc 4 canaux/parties qui vont jouer ensemble une partition définie. Chaque instrument va avoir 
        son canal attitré, avec un son particulier. Les voici : 
      </p>
      <center><img src="/tuto/chiptune2.png" class="w-2/3"/></center>
      <p class="my-4  text-justify">
        Chaque canal est visible et accessible en haut à droite de l’écran. Ces 4 boutons vont permettre de 
        visualiser les notes des partitions de chaque canal / partie : 
      </p>
      <center><img src="/tuto/chiptune3.png" class="w-64"/></center>
      <p class="my-4  text-justify">
        En cliquant sur l'œil, on pourra cacher les notes de la partition du canal, en cliquant sur le haut 
        parleur, on pourra mettre en sourdine le canal.
      </p>

      <SubTitle name="1.3 Les instruments" />
      <div class="lg:flex mb-4">
        <div class="w-1/2">
          <img src="/tuto/chiptune4.png" />
        </div>
        <div class="">
          <p class="ml-4 text-justify">
            Les instruments disponibles sont visibles dans la partie Instruments sur le côté gauche de l’écran. <br />
            Ils sont divisés en 3 groupes : Duty, Wave, et Noise. Chaque groupe possède un certain nombre d'instruments, 
            c'est-à-dire de type de son différent. <br />
            Pour écrire une mélodie avec un instrument, il faut donc cliquer dessus, puis cliquer sur la case qui correspond 
            à la note que l’on souhaite jouer.<br />
            Attention ! Un instrument ne peut être joué que dans le canal du même nom. Par exemple, l’instrument 12.5% Pulse
            appartenant au groupe Duty, ne pourra être joué que dans les canaux Duty 1 ou Duty 2. De même, un instrument Noise 
            comme Closed Hi-Hat 2 ne pourra être joué que dans le canal Noise
          </p>
        </div>
      </div>

      <SubTitle name="1.4 La partition / La grille de note" />
      <p class="my-4  text-justify">
        La grille au centre de l’écran représente la <b>Partition</b> du canal sélectionné qui sera jouée par la game boy. 
        Après avoir sélectionné un instrument et s’être placé dans son canal correspondant, on peut écrire des notes dans cette grille. <br /><br />
        - <b>Pour ajouter des notes à la partition</b>, on clique sur la case souhaitée. Cela ajoute un carré de couleur, qui indique qu’une 
        note est placé. <br />
        - <b>Pour les enlever une note</b>, cliquez droit sur la note.<br /><br />
        <b>On peut placer la note verticalement et horizontalement.</b><br />
        - <b>Verticalement :</b><br />
        Plus on met une note haute dans la grille, plus elle sera aigüe. A l’inverse, plus on met une note basse, plus elle sera grave. 
        A gauche de la grille, on peut voir les différentes notes possibles, sous la forme d’un piano. (voir partie 3. créer une mélodie).
        <br /><br />
        - <b>Horizontalement :</b><br />
        En plaçant les notes les unes à la suite des autres, on va créer une mélodie. Lorsqu’on appuiera sur Play, 
        elles seront jouées les unes à la suite des autres, en fonction du temps écoulé. Plus deux notes sont espacées horizontalement, 
        plus il y aura de temps écoulé entre les 2 sons.
      </p>

      <SubTitle name="1.5 Les boutons d’action, le tempo" />
      <div class="flex">
        <div><img class="w-16" src="/tuto/chiptune5.png" /></div>
        <div><p class="ml-4 my-4 text-justify">
            <b>Enregistrer :</b> Permet de sauvegarder le projet. 
        </p></div>
      </div>
      <div class="flex">
        <div><img class="w-16" src="/tuto/chiptune6.png" /></div>
        <div><p class="ml-4 my-4 text-justify">
            <b>Play :</b> Permet de jouer la chanson écrite. Tous les canaux vont joué simultanément (sauf s’ils sont en sourdines)
        </p></div>
      </div>
      <div class="flex">
        <div><img class="w-16" src="/tuto/chiptune7.png" /></div>
        <div><p class="ml-4 my-4 text-justify">
            <b>Stop :</b> Quand on joue la chanson, permet d’arrêter.
        </p></div>
      </div>
      <div class="flex">
        <div><img class="w-16" src="/tuto/chiptune8.png" /></div>
        <div><p class="ml-4 my-4 text-justify">
            <b>Ecrire :</b> Permet d’écrire des notes dans la partition
        </p></div>
      </div>
      <div class="flex">
        <div><img class="w-16" src="/tuto/chiptune9.png" /></div>
        <div><p class="ml-4 my-4 text-justify">
            <b>Effacer :</b> Permet d’effacer des notes dans la partition
        </p></div>
      </div>
      <div class="flex">
        <div><img class="w-16" src="/tuto/chiptune10.png" /></div>
        <div><p class="ml-4 my-4 text-justify">
            <b>Sélectionner :</b> Permet de sélectionner plusieurs notes pour les déplacer, les supprimer, etc…
        </p></div>
      </div>
      <p class="my-4 text-justify">
        <b>-  Le tempo (la vitesse du morceau)</b><br />
        En haut à droite, on peut voir une section Rythme, avec une valeur de 1 à 20. Plus sa valeur est faible, 
        plus la vitesse sera rapide, plus elle est élevée, plus le tempo sera lent.
      </p>

      <SubTitle name="1.6 Les patterns" />
      <p class="my-4 text-justify">
        En bas de l’écran, on peut voir les patterns du morceau.<br />
        Une musique chiptune est une succession de patterns. On peut en créer de nouveaux, en dupliquer, et les placer 
        comme on le souhaite dans le morceau. Lorsqu’on appuie sur Play les patterns seront lus les uns après les autres.<br />
        Voici un exemple de patterns dans une musique chiptune : 
      </p>
      <center><img src="/tuto/chiptune11.png" /></center>
      <p class="my-4 text-justify">
        Ici le pattern 00 sera lu, puis le 01, puis le 02 etc.. Ici, on voit qu le pattern 03 est disposé deux fois. 
        Il sera donc lu deux fois. Cette méthode permet de répéter des phrases musicales (pour un refrain par exemple).
      </p>

      <!-- PART 2 - PARTIE RYTHMIQUE -->
      <p id="2" class="text-green-400 font-bold text-xl mb-4">2. Créer une partie rythmique (“beat”)</p>
      <p class="my-4 text-justify">
        Dans cette partie, nous allons voir comment créer une partie / section rythmique. Dans les musiques plus traditionnelles, 
        cette partie est généralement assurée par une batterie, des percussions (formations rock, jazz…), ou par une boite à rythme 
        (musiques electroniques). En chiptune, cette partie sera assurée par le canal <b>noise</b> qui imitera une boite à rythme.
      </p>

      <SubTitle name="2.1 Le kick (grosse caisse)" />
      <p class="my-4 text-justify">
        Le kick (ou grosse caisse), est l’élément rythmique qui désigne la base rythmique. C’est souvent un son grave (“boum”). 
        C’est lui qui donne le rythme, et qui donne souvent envie de hocher la tête.
      </p>
      <p class="my-4 text-justify">
        Pour écrire la partition du kick sur GB Studio, sélectionner, dans le groupe <b>Noise</b>, l’instrument <b>11: Bass Drum</b> ou 
        <b>12: Bass Drum2</b> selon vos préférences. Positionnez vous sur le canal Noise en cliquant sur son bouton en haut à droite.<br />
        Pour cette partie, nous allons admettre que nous voulons un morceau de musique electronique / techno. On veut donc que le kick 
        soit présent sur chaque temps / pulsation afin de créer une chanson prenant, qui fasse hocher la tête.<br />
        Positionnez vous donc sur la ligne <b>C5</b> et cliquez sur chaque case précédée d’une barre principale (pulsation). Ce sont les barres 
        qui sont un peu plus épaisses sur la grille.<br />
        Voici le résultat que vous devriez obtenir : 
      </p>
      <center><img src="/tuto/chiptune12.png" class="lg:w-1/2" /></center>

      <SubTitle name="2.2 La snare (caisse claire)" />
      <p class="my-4 text-justify">
        La snare (ou caisse claire), est un autre élément/son rythmique, plus aigu (“paf”) qui va permettre de donner plus de couleur 
        à notre section rythmique. Elle peut s’apparanter au son produit par un “clap” lorsqu’on applaudit avec ses mains. <br />
        Nous allons rester sur notre exemple de musique electro / techno. Dans ce style de musique, la snare est souvent placée à la 2ème 
        et la 4ème pulsation de chaque mesure. <br />
        Une mesure étant composée de 4 temps, on placera donc une snare toutes les deux pulsations. Dans GB Studio, une pulsation est 
        représentée par une barre un plus épaisse.<br />
        Un canal d’une Game boy ne peut malheureusement jouer qu’une seule note à un instant t. Il faudra donc jouer la snare à la place 
        du kick à ces pulsations. Dans un morceau de techno classique, le kick et la snare sont généralement joués ensemble sur le 2ème 
        et 4ème temps (pulsation).<br />
        Pour écrire la partition de snare, sélectionnez, dans le groupe <b>Noise</b>, un instrument <b>Snare Drum</b>. Toujours dans le 
        canal Noise, positionnez vous sur la ligne <b>C5</b>, puis cliquez sur chaque case après chaque 2 pulsation principales 
        (barres plus épaisses). Voilà le résultat que vous devriez obtenir :
      </p>
      <center><img src="/tuto/chiptune13.png" class="lg:w-1/2" /></center>

      <SubTitle name="2.3 Les hihats" />
      <p class="my-4 text-justify">
        Le hi hat, ou charleston, est un élément rythmique aigu, (“tic”) qui sera joué de manière à combler les vides rythmiques et ajouter 
        un “groove”, c’est-à-dire un rythme qui incite à bouger / hocher la tête. En chiptune, on le place généralement entre le kick et la 
        snare. <br />
        Pour notre exemple techno, nous allons avoir un hi hat assez rapide. <br />
        Sélectionnez, dans le groupe <b>Noise</b>, l’instrument <b>Closed Hi-Hat 2</b>, et toujours dans le canal <b>Noise</b> sur la ligne 
        <b>C5</b>, cliquez sur chaque 2 cases : 
      </p>
      <center><img src="/tuto/chiptune14.png" class="lg:w-1/2" /></center>
      <p class="my-4 text-justify">
        Il existe généralement 2 types de hihat : les <b>closed</b> et les <b>open</b>. Les closed hi hat sont des impacts très courts qui vont servir 
        le rythme, les open hihat sont des impacts un peu plus long, “ouvert”, qui vont faire respirer le morceau. Ici, on pourrait décider 
        de mettre un open hihat toutes les 4 cases, pour ouvrir un peu plus le morceau et servir le “groove” :
      </p>
      <center><img src="/tuto/chiptune15.png" class="lg:w-1/2" /></center>
      <div class="flex my-4">
        <div>
          <img src="/tuto/chiptune16.png" />
        </div>
        <div>
          <p class="ml-4 text-justify">
            Les hi hats sont généralement moins forts que le kick et la snare. Vous pouvez régler le volume des hi hats, en sélectionnant 
            chaque instrument que vous avez choisi pour vos hi hats, et en changeant la valeur <b>Volume initial</b> à gauche de l’écran. 
            (Voir ci-contre)
          </p>
        </div>
      </div>

      <SubTitle name="2.4 Créer un pattern efficace" />
      <p class="my-4 text-justify">
        Maintenant que l’on sait comment écrire un pattern rythmique, c’est le moment de laisser aller sa créativité !<br />
        - On peut par exemple trouver la section rythmique trop lente ou trop rapide. On peut alors changer le tempo du morceau. 
        Pour ce faire, changez la valeur <b>Rythme</b> en haut à droite de l’écran. Plus la valeur est faible, plus le rythme sera rapide.<br />
        - On peut également changer de rythme. Certains styles de musique utilisent d’autres rythmes que le traditionnel 
        <b>kick - snare - kick - snare</b>. Essayez de placer d’autres <b>kick</b>, changez le rythme des hi hat… Expérimentez !<br />
        Il n’y a pas vraiment de règles. Si vous comptez faire un morceau plutôt dansant, il est de bon temps d’avoir un kick sur chaque 
        pulsation. Si vous êtes plutôt musiques planantes ou “slow”, disposez vos kicks et vos hihats avec parcimonie, et pas forcément 
        sur les pulsations. Les musiques plus “extrêmes”, comme le punk-rock ou le métal, utilisent encore plus de kick, parfois 4 fois 
        par pulsations ! Enfin, certaines chansons n’utilisent pas du tout de sessions rythmiques, dans une logique d’épurement. 
        C’est donc le moment de choisir quel genre de musique vous aimeriez écrire. Si vous n’avez pas d’idée, vous pouvez garder celui 
        que l’on a déjà écrit.
      </p>
      <center><img src="/tuto/chiptune17.png" class="lg:w-1/2" /></center>
      <p class="my-4 text-center italic">
        Une section rythmique plus allégée / douce. Ici, le kick est placé à chaque pulsation 1 et 3, avec quelques 
        kick disposés un peu avant la snare, pour donner un groove lent. Les hi hat eux, ne sont joués qu’à chaque demi pulsations.
      </p>
      <center><img src="/tuto/chiptune18.png" class="lg:w-1/2" /></center>
      <p class="my-4 text-center italic">
        Une section rythmiques plus “extrême”, pour du métal. Ici les kicks sont présents à quasi chaque 2 cases. 
        Les cases restantes sont comblées par des hi hats.
      </p>

      <!-- PART 3 - PARTIE MELODIE -->
      <p id="3" class="text-green-400 font-bold text-xl mb-4">3. Créer une mélodie</p>
      <p class="my-4 text-justify">
        Maintenant que nous avons une section rythmique, c’est le moment de s’attaquer à la mélodie de notre chanson. 
        Une mélodie est une succession de notes de musique qui va jouer un air, une phrase que l’on pourra chanter, qui pourra rester 
        dans la tête. Dans les chansons traditionnelles, c’est souvent la voix d’un.e chanteur.euse qui assure ce rôle. Mais certains 
        styles instrumentaux utilisent plutôt la guitare, le piano… En chiptune, il s’agira d’utiliser des instruments square ou wave.
      </p>

      <SubTitle name="3.1 Un peu de théorie musicale" />
      <p class="my-4 text-justify">
        <b>- Les notes et leur notation</b><br />
        Il existe 7 notes en musique : Do - Ré - Mi - Fa - Sol - La - Si.<br />
        Sur un piano, elles sont disposées de cette façon : 
      </p>
      <center><img src="/tuto/chiptune19.png" class="lg:w-1/2" /></center>
      <p class="my-4 text-justify">
        Dans plusieurs pays d’Europe, dont la France, on utilise les noms Do - Ré - Mi… <br />
        Cependant, il existe également la notation <b>américaine</b> constitué de lettres de l’alphabet : 
      </p>
      <p class="my-4 text-center">
        <b>C - D - E - F - G - A - B.</b>
      </p>
      <p class="my-4 text-justify">
        C’est cette notation qui est utilisée dans GB Studio.
        On a aussi souvent l’occasion de rencontrer cette notation : “C5”, “B6”, “F3”... La lettre définit la note jouée. 
        Le chiffre, lui, définit <b>l’octave</b> à laquelle cette note sera jouée. En effet, plus le chiffre sera petit (3, 4), 
        plus note sera grave. Plus le chiffre sera grand (7, 8), plus elle sera aiguë.<br />
        Les notes noires sont également des notes qui peuvent être jouées, et qui sont une sorte d'intermédiaire entre 
        les notes blanches qui l'entourent. Par exemple, si l’on joue la note située entre C et D, on jouera une note qui se 
        situe à une hauteur entre Do et Ré. On appellera cette note <b>Do♯</b> (Do <b>dièse</b>, c’est à dire un Do “augmenté”), 
        ou <b>Ré♭</b> (Ré <b>bémol</b>, c’est à dire un Ré “diminué”).
      </p>
      <p class="my-4 text-justify">
        <b>- Les gammes</b><br />
        La gamme est une suite de notes permettant d’avoir un ensemble de notes qui vont sonner ensemble de manière à 
        exprimer une émotion particulière. La plus connue est probablement la gamme de <b>Do Majeur (C Majeur)</b>.
      </p>
      <p class="my-4 text-center">
        <b>Gamme de Do Majeur (C Majeur) :</b><br />
        Do - Ré - Mi - Fa - Sol - La - Si<br />
        C - D - E - F - G - A - B
      </p>
      <p class="my-4 text-justify">
        Les deux types principaux de gammes sont la gamme <b>mineur</b> et <b>Majeur</b>.
        Ce sont des suites de notes qui vont apporter une couleur particulière à notre mélodie. <br />
        <b>La gamme Majeur</b> aura une couleur enjouée, heureuse, victorieuse.<br />
        <b>La gamme mineure</b> aura une couleur plus triste, plus mélancolique. Par exemple : 
      </p>
      <p class="my-4 text-center">
        <b>Gamme de Do mineur :</b><br />
        Do - Ré - Mi♭ - Fa - Sol - La♭- Si ♭<br />
        C - D - E♭- F - G - A♭- B♭
      </p>
      <p class="my-4 text-justify">
        Il est possible d’utiliser une autre note pour constituer sa gamme. Le plus simple est de chercher sur 
        internet la gamme voulue et de récupérer les notes de cette gamme. Par exemple voilà ce que donne la Gamme 
        de <b>La Mineur</b> : La - Si - Do - Ré - Mi - Fa - Sol
      </p>
      <p class="my-4 text-justify">
        <b>Plus d’explications :</b><br />
        L’écart entre deux notes est calculé en nombre de tons. Par exemple, entre Do et Ré, il y a <b>1 ton</b>. Cependant, 
        entre Mi et Fa, ou entre Si et Do, il n’y a qu’<b>½ ton</b>. Elles sont repérables par le fait que sur un piano, il n’y a pas 
        de touches noires entre elles. Les touches noires symboles les <b>½ tons</b> : en effet, entre Do et Do# il y a ½ ton, et 
        entre Do# et Ré, il y a également ½ ton. <br />
        Pour constituer une gamme de son choix, il y a un schéma de tons et ½ tons à respecter.
      </p>
      <p class="my-4 text-center">
        <b>Pour une gamme majeur :</b> <br />
        <b>I</b> - 1 ton - <b>II</b> - 1 ton - <b>III</b> - ½ ton - <b>IV</b> - 1 ton - <b>V</b> - 1 ton - <b>VI</b> - 1 ton - <b>VII</b> - ½ ton - <b>VIII</b><br />
        <b>Pour une gamme mineur : </b><br />
        <b>I</b> - 1 ton - <b>II</b> - ½ ton - <b>III</b> - 1 ton - <b>IV</b> - 1 ton - <b>V</b> - 1 ton - <b>VI</b> - ½ ton - <b>VII</b> - 1 ton - <b>VIII</b>
      </p>
      <p class="my-4 text-justify">
        La première note sera toujours celle de la gamme choisie. Ainsi I = Sol si on choisit la gamme de Sol Majeur. 
        Ensuite, il suffit de calculer les notes suivantes en ajoutant la quantité de tons indiquée.
      </p>

      <SubTitle name="3.2 Composer une mélodie" />
      <p class="my-4 text-justify">
        <b>Différentes méthode pour trouver une bonne mélodie :</b><br />
        <b>- La méthode “chantonnée” : </b><br />
        Essayez de chantonner ou de siffler une mélodie que vous inventez dans votre tête. Suite à ça, essayer de trouver les 
        notes sur la grille de GB Studio <br />
        <b>La méthode “à l’aveugle” : </b><br />
        Choisissez une gamme en fonction de vos goûts et de l’intention que vous souhaitez donner (chanson joyeuse, enjouée ? => 
        Majeur, chanson triste ? => mineure). Si vous êtes novice en terme de théorie musicale, choisissez la gamme de Do Majeur 
        pour faire plus simple.
        Suite à ça, essayez de placer des notes de cette gamme sur la grille de GB Studio. Cette méthode peut être longue. A chaque édition, 
        il faut jouer le morceau, voir si le résultat nous convient, éditer la mélodie, réessayer, etc… Mais peut donner des résultats intéressants. <br />
        <b> La méthode du musicien :</b><br />
        Si vous jouez déjà d’un instrument, il peut être intéressant d’essayer de la composer sur votre instrument (un piano, une guitare), 
        puis de la retranscrire de GB Studio.
      </p>
      <p class="my-4 text-justify">
        <b>Quelques astuces :</b><br />
        - Si vous ne savez pas par où commencer, cela peut être une bonne idée de commencer par placer au début la première de la gamme 
        (par ex un C pour la gamme de C Majeur). Cela peut également fonctionner pour la 3ème ou la 5ème note (E ou G pour C Majeur).<br />
        - Pour placer rythmiquement les notes, commencer par placer les notes de façon régulière (par exemple, tous les 2 ou les 4 cases). 
        Une fois que la suite de note vous plaît, essayer de modifier légèrement le rythme pour rendre la mélodie encore plus intéressante.
      </p>

      <SubTitle name="3.3 Écrire sa mélodie dans GB Studio" />
      <p class="my-4 text-justify">
        La mélodie peut s’écrire soit dans les canaux <b>Duty</b>, soit dans le canal <b>Wave</b>. Ici, pour plus de simplicité, on se 
        cantonnera au canal <b>Duty 1</b>. Sélectionnez donc ce canal en cliquant sur son bouton en haut à droite de l’écran.<br />
        Sélectionnez ensuite l’instrument de votre choix dans le groupe <b>Duty</b>. Il y a différents types de sons différents :<br />
        <b>- Les Fade Out :</b> Ce sont des notes assez longues, qui vont s’atténuer avec le temps. Cet instrument peut être intéressant pour 
        les mélodies expressives, émotives.<br />
        <b>- Les Short :</b> Ce sont des notes assez courtes, (“piquées”), qui peuvent être utilisées si on a besoin de jouer une mélodie rapide
         avec beaucoup de notes, ou pour des mélodies “mignonnes”, “espiègles”.<br />
        <b>- Les xx% Pulse :</b> Une note <b>Pulse</b> sera jouée jusqu’à temps qu’une prochaine note soit jouée. Pour arrêter une note <b>Pulse</b> 
        sans autre note, Il faudra jouer une note <b>15: (empty)</b>, à l’endroit où l’on veut que la note s’arrête.
      </p>

      <p class="my-4 text-justify">
        Une fois votre instrument choisi, c’est le moment d’écrire la partition de votre mélodie !<br />
        Une mélodie est souvent aiguë ou médium. Il faut donc se placer dans les <b>octaves 4 à 8</b>. Ainsi, si vous voulez que votre musique soit 
        plutôt aiguë, et qu’elle commence par un Do (C) il faut donc placer la note sur la ligne C6, dans la première colonne. Transcrivez la 
        suite de votre mélodie à la suite de celle-ci. <br />
        Faites des essais régulièrement : Placez quelques notes, appuyez sur <b>Play</b> pour écouter le résultat, ajustez les notes de manière à 
        régler l’endroit où elles sont jouées et leur note, puis continuez, réessayez, etc… Cela peut paraître fastidieux au début, mais 
        l’expérience vous permettra d’être plus efficace pour vos prochaines chansons.
      </p>

      <SubTitle name="3.4 Pas d’idée de mélodie ? Essayons avec celle de Mario !" />
      <p class="my-4 text-justify">
        Si vous ne trouvez pas d’idée de mélodie, pas de soucis. Essayez d’abord de reproduire des mélodies qui existent déjà.
        Sur internet, il peut être assez facile de trouver les partitions de certaines chansons connues. Essayez de les reproduire sur GB Studio. 
        Cela permet de prendre en main le logiciel, et l’écriture musicale. Analysez ensuite les morceaux écrits : Pourquoi fonctionne-t-il ? 
        Quels rythmes sont utilisés ? Petit à petit, vous aurez des éléments vous permettant d’écrire vos propres mélodies !
      </p>
      <p class="my-4 text-justify">
        Essayez, par exemple, par reproduire le début de la mélodie principale de Super Mario Bros:
      </p>
      <center><img src="/tuto/chiptune20.png" class="lg:w-1/2" /></center>

      <!-- PART 4 - PARTIE BASS -->
      <p id="4" class="text-green-400 font-bold text-xl mb-4">4. Créer une basse</p>
      <SubTitle name="4.1 La basse ? Qu’est ce que c’est ?" />
      <p class="my-4 text-justify">
        La basse est une mélodie qui va être jouée dans les graves / basses fréquences. Elle sert à accompagner le morceau, 
        et lui donner plus de “corps”. Dans les chansons plus traditionnelles, elle est souvent jouée par de la guitare basse ou par un 
        synthétiseur.
      </p>

      <SubTitle name="4.2 Composer une ligne mélodique de basse" />
      <p class="my-4 text-justify">
        Nous allons essayer de composer une ligne mélodique de basse pour notre chanson. En chiptune elle est généralement jouée par un 
        canal <b>Wave</b> ou <b>Duty</b>. Pour faciliter les choses, ici, on écrira la partition de la basse dans le canal <b>Duty</b>. 
        Sélectionnez donc ce canal en cliquant sur le bouton <b>Duty</b> en haut à droite de l’écran.<br />
        Sélectionnez ensuite l’instrument de votre choix dans le groupe Duty. Plusieurs instruments sont disponibles :<br />
        <b>- Les Pulses :</b> Ce sont des instruments qui imitent les instruments duty. Ils ont un peu agressifs, et sont idéales pour écrire 
        une basse qui a du punch !<br />
        <b>- Les Triangular Wave ou Saw Wave</b> auront un son un peu plus rond, plus doux.
      </p>
      <p class="my-4 text-justify">
        Comme dans la partie mélodie, la plupart des notes jouées par ces instruments ne s’arrêteront que lorsqu’une nouvelle note sera jouée. 
        Si vous souhaitez qu’elle s’arrête plus tôt, sélectionnez l’instrument <b>15: (empty)</b> cliquer sur une case à l’endroit où vous voulez que 
        la note s’arrête de jouer.
      </p>
      <p class="my-4 text-justify">
        <b>Ecrire une ligne de basse :</b><br />
        Comme expliqué précédemment, une basse, comme son nom l’indique, joue dans les octaves plus bas, plus grave (généralement à l’octave 4 
        ou 3). Si on veut jouer un Do (C), il faudra donc placer la note en C4. <br />
        Il y a plusieurs principes qui peuvent aider à écrire une ligne de basse :<br />
        <b>- La tonalité du morceau :</b> Si vous avez choisi une gamme spécifique pour écrire votre mélodie, par exemple Do Majeur, la tonalité 
        sera Do (C). Une bonne pratique est donc de commencer par placer un Do en première note dans sa ligne de basse. Si vous ne connaissez 
        pas la gamme de votre morceau, il est possible de la deviner en regardant les notes qui reviennent le plus et en cherchant dans quelle 
        gamme elles sont présentes.<br />
        <b>- La tonalité d’une mesure :</b> Pour chaque mesure (lapse de temps entre deux séparations verticales épaisses), regardez les notes jouées. 
        Pour trouver la note de basse, on peut se baser sur plusieurs choses : <br />
        La première jouée à cette mesure définit souvent la tonalité de cette mesure. S’il s’agit d’un Mi (E), il y a de forte chance que 
        la note de basse E fonctionne bien avec cette mesure. Si ça ne fonctionne pas, observez s’il y a des notes qui reviennent fréquemment. 
        Si c’est le cas, il peut être intéressant d’utiliser cette note de basse pour cette mesure.
      </p>
      <p class="my-4 text-justify">
        Par exemple, pour la mélodie de Super Mario Bros : 
      </p>
      <div class="lg:flex mb-4">
        <div class="lg:w-1/3">
          <img src="/tuto/chiptune21.png" class="w-full" />
        </div>
        <div class="lg:w-2/3">
          <p class="ml-4 text-justify">
            <b>- Mesure 1 :</b> En analysant la mélodie de Mario, je remarque que la grande majorité des notes appartiennent à la gamme 
            de Do Majeur ou de La Mineur. Cependant, la mélodie de Mario est plutôt enjouée. Je vais donc choisir la gamme de Do Majeur. 
            Je place donc un Do (C4) au début de mon morceau.<br />
            <b>- Mesure 2 :</b> Les deux notes jouées sont des des Sol (G6 et G5). Pas de doute possible, c’est bien un Sol que je dois jouer 
            à cette mesure<br />
            <b>- Mesure 3 :</b> La première note jouée est un Do. De plus, dans le mouvement de la mélodie, on remarque un nouveau départ, 
            qui nous fait penser que les 2 premières mesures n’étaient qu’une introduction. Il est donc censé d’écrire un Do à nouveau. 
            Bonus : les 3 notes jouées sur cette mesure font partie de l’accord de Do Majeur, c’est une raison supplémentaire.
          </p>
        </div>
      </div>
      <p class="my-4 text-justify">
        En procédant ainsi, on va pouvoir placer une note pour chaque mesure de notre mélodie.<br />
        Si on veut agrémenter un peu notre ligne de basse, on peut : <br />
        <b>- Répeter plusieurs fois ces notes.</b> Cette technique est très utilisée dans le rock ou dans le disco. On peut également 
        placer certaines de ces notes à l’octave au-dessus (un C4 deviendra donc un C5). Cela peut créer un effet de rebond intéressant.<br />
        <b>- Ajouter des “notes de passages”</b>, c’est-à-dire des notes qui vont faire la liaison entre les 2 mesures, en respectant 
        la mélodie et la gamme. Par exemple, dans mon exemple précédent,  2 cases avant le G (Sol) de la mesure 2, je pourrais 
        ajouter un A (La) qui fait la liaison entre le C (Do) et le G (Sol)<br />
        <b>- Ajouter des notes pour ajouter de l’accompagnement à la mélodie.</b> Par exemple, pour Mario, je pourrais décider 
        d’ajouter un E (Mi) (très présent dans la partie mélodique de cette mesure) à la moitié de la première mesure, entre 
        le C (Do) et le G (Sol).
      </p>
      <div class="lg:flex mb-4">
        <div class="lg:w-1/3">
          <img src="/tuto/chiptune22.png" class="w-full" />
        </div>
        <div class="lg:w-2/3">
          <p class="ml-4 text-justify">
            Ci-contre, un exemple de basse funky / disco pour Mario. <br />
            Ici, j’ai joué 4 fois la même notes à chaque mesure, en mettant une note sur deux à l’octave du dessus.<br />
            Les carrés gris sont l’instrument empty pour arrêter les notes très rapidement.<br />
            Le tout donne un effet très “sautillant”. C’est d’autant plus efficace si un kick est joué sur chaque note basse 
            (en début et milieu de mesure).
          </p>
        </div>
      </div>

      <!-- PART 5 - PARTIE ACCOMPAGNEMENT -->
      <p id="5" class="text-green-400 font-bold text-xl mb-4">5. Créer des accompagnements</p>
      <SubTitle name="5.1 Un accompagnement ? Qu’est ce que c’est ?" />
      <p class="my-4 text-justify">
        Notre chanson commence à être plutôt complète. Il y a une session rythmique, une mélodie dans les aigus, une ligne de basse. 
        Il est encore d’ajouter plus de corps au morceau en ajoutant un accompagnement dans les médiums, c'est-à-dire entre la basse et 
        la ligne mélodique. Cela peut prendre plusieurs formes : <br />
        - On peut vouloir jouer un complément à la mélodie, en la jouant un octave en dessous pour lui ajouter plus de matières, 
        et en jouant des notes complémentaires à celle-ci.<br />
        - On peut également jouer des accords. Dans les chansons plus traditionnelles, il n’est pas rare de voir des guitares ou un piano 
        jouer des accords pour accompagner le morceau.<br />
        - On peut aussi jouer des arpèges, des successions de notes dans la gammes du morceaux qui vont donner plus de vie à celui-ci.
      </p>

      <SubTitle name="5.2 Doubler la mélodie" />
      <p class="my-4 text-justify">
        La façon la plus simple de compléter notre morceau, serait de doubler la mélodie. On va donc copier coller la mélodie de notre 
        morceau dans un canal non utilisé, ici <b>Duty 2</b>, et le mettre un octave en dessous.<br />
        Pour ce faire, cliquer sur l'icône <b>Sélection</b> en haut de l’écran. Positionnez vous dans le canal <b>Duty 1</b>, sélectionnez toute la 
        mélodie, appuyez sur Ctrl+C (ou Cmd+C sur MAC), pour le copier. Ensuite, positionnez-vous dans le canal <b>Duty 2</b>, puis appuyez sur 
        Ctrl+V (ou Cmd+V sur MAC). Ensuite, glissez votre souris vers le bas pour retrouver les bonnes notes, mais un octave en dessous, 
        puis cliquez pour les insérer dans la grille.<br />
        Pour que ça reste mélodique, il est préférable de réécrire cette mélodie en utilisant un autre instrument que celui utilisé dans 
        <b>Duty 1</b>. Généralement, il est aussi fortement recommandé de baisser le volume de celui-ci pour que la mélodie en <b>Duty 1</b> reste 
        principale. Pour se faire, baisser la valeur du Volume Initial de l’instrument, située à droite de l’écran.
      </p>
      <p class="my-4 text-justify">
        On peut également opérer quelques petits changements dans la mélodie du <b>Duty 2</b>, en y changeant certaines notes par des notes 
        complémentaires à la mélodie du <b>Duty 1</b>.
      </p>
      <p class="my-4 text-justify">
        Une notion importante pour mener à bien cette opération est le concept de <b>Dissonance / Consonance</b>.<br />
        Si deux notes sont jouées en même temps, elles sonneront “bien” (consonance) ou “mal” (dissonance). Par exemple, deux notes 
        voisines dans la gamme sonneront dissonantes (comme Si et Do, dans une gamme de Do Majeur). A l’inverse, des tierces ou des quintes 
        (Do - Mi ou Do - Sol en Do Majeur), sonneront particulièrement bien (consonance). Il faut veiller à ce que votre arrangement et votre 
        mélodie soient consonants (En tout cas, si vous voulez que votre morceau soit consonant).
      </p>

      <SubTitle name="5.3 Créer des arpèges" />
      <p class="my-4 text-justify">
        Un arpège est une suite de notes assez rapide dans une même gamme. Il peut être intéressant d’écrire des arpèges dans la partie 
        accompagnement afin de rendre notre chanson plus vivante.<br />
        Une méthode facile consiste à prendre la note de basse de la mesure dans laquelle on se trouve (Do - C par exemple), 
        trouver son accord majeur ou mineur suivant le contexte (C Majeur) et ses notes (Do - Mi - Sol / C - E - G), et disposer ces 
        notes à la suite des autres.
      </p>
      <p class="my-4 text-justify">
        Un exemple d’arpège pour Mario :
      </p>
      <center><img src="/tuto/chiptune23.png" class="lg:w-1/2" /></center>

      <SubTitle name="5.4 L’Arpegiator ou comment donner la sensation d’accords" />
      <p class="my-4 text-justify">
        Une dernière méthode pour accompagner le morceau consiste à jouer des accords. Un accord, c’est un groupe de notes joué en 
        même temps. Le souci, c’est qu’il ne nous reste qu’un canal disponible, et qu’il n’est possible de jouer qu’une seule note 
        à la fois dans un canal…<br />
        Il y a une solution à cela : GB Studio a une fonction appelée <b>Arpegiator</b>. Grâce à celle-ci, on va pouvoir jouer de façon 
        très rapide, une note donnée, ainsi que 2 autres notes définies à la suite, très rapidement, pour donner l’illusion
         d’entendre réellement un accord.<br />
        Pour ce faire, comme pour les arpèges, regarder la note principale de basse d’une mesure. Et écrivez cette note au même 
        endroit, un ou deux octaves plus haut, dans le canal <b>Duty 2</b>.<br />
        En bas de la grille, il y a une ligne avec une baguette magique. Cliquez sur la case de cette ligne dans la même colonne 
        que la note que vous avez ajoutée.
      </p>
      <center><img src="/tuto/chiptune24.png" class="" /></center>
      <p class="my-4 text-justify">
        Un 0 devrait s’afficher, cela signifie que l’arpegiator est enclenché. Dans les paramètres de l’Arpegiator à gauche de 
        l’écran. Un arpegiator a deux valeurs, correspondant aux 2 autres notes de votre accord : <br />
        <b>- First note + semitone</b>, c’est la valeur en ½ tons de l’intervalle entre la première et la deuxième note (pour un accord 
        majeur : <b>4</b>, pour un accord mineur : <b>3</b>)<br />
        <b>- Second Note + semitone</b>, les ½ tons de l’intervalle entre la première et la deuxième note (<b>7</b>)
      </p>
      <p class="my-4 text-justify">
        Ainsi, si on veut faire un C Majeur, on mettra la note C, puis les valeurs 4 et 7, si on veut un C mineur, on mettra 
        les valeurs 3 et 7. Répétez cette opération pour chaque mesure, et vous aurez un accompagnement d’accord pour votre chanson ! 
        Il est possible que vous ayez envie que votre accord dure plus longtemps. dans ce cas, copier plusieurs notes à la suite avec 
        le même effet arpegiator.
      </p>

      <!-- PART 6 - BONUS -->
      <p id="6" class="text-green-400 font-bold text-xl mb-4">6. Pour aller plus loin, pour en découvrir plus</p>
      <SubTitle name="6.1 En apprendre plus sur GB Studio et sur la composition musicale" />
      <p class="my-4 text-justify">
        Nous n’avons qu’aborder que les principes de base de la composition et de la chiptune, mais si le sujet vous intéresse, 
        voici quelques liens qui pourront vous être utile pour en apprendre plus : 
      </p>
      <p class="my-4 text-justify">
        <b>- Learn Music with Ableton</b> 
        (<a class="text-green-400" href="https://learningmusic.ableton.com/">https://learningmusic.ableton.com/</a>)<br />
        Ce site propose des exercices amusants permettant d’apprendre beaucoup de choses concernant la composition musicale. 
        Cependant, il est en anglais.<br />
        <b>- Apprendre le solfege</b> 
        (<a class="text-green-400" href="https://www.apprendrelesolfege.com/">https://www.apprendrelesolfege.com/</a>)<br /> 
        Un site en français qui permet d’apprendre le solfège et la composition musicale.<br />
        <b>- Tutoriel Youtube de GB Studio en français</b>
        (<a class="text-green-400" href="https://www.youtube.com/watch?v=pDjO1l9HhtE&list=PLkDZpqd__q2NKc36Auknl3O_4Mfrut0iG">Youtube</a>)<br />
        Si vous souhaitez apprendre à faire un jeu de Game Boy avec GB Studio.<br />
        <b>- Le site GB Studio</b>
        (<a class="text-green-400" href="https://www.gbstudio.dev/">https://www.gbstudio.dev/</a>)<br />
        GB Studio est un logiciel totalement gratuit qui permet de créer des jeux et de la musique de Game Boy. Vous trouverez 
        sur ce site le logiciel à télécharger ainsi qu’une documentation complète du logiciel.
      </p>

      <SubTitle name="6.2 Quelques artistes notable de musique chiptune" />
      <p class="my-4 text-justify">
        Si la musique chiptune vous intéresse, voici quelques artistes notables du genre :
      </p>
      <p class="my-4 text-justify">
        - <b>Chipzel</b> (UK - Dance / Chiptune) : 
        <a class="text-green-400" href="https://chipzelmusic.bandcamp.com/album/spectra">Écouter sur Bandcamp</a><br />
        - <b>Xyce</b> (NL - Pop / Chiptune) : 
        <a class="text-green-400" href="https://xyce.bandcamp.com/">Écouter sur Bandcamp</a><br />
        - <b>Anamanaguchi</b> (US - Rock / Chiptune) : 
        <a class="text-green-400" href="https://anamanaguchi.bandcamp.com/music">Écouter sur Bandcamp</a><br />
        - <b>TRIAC</b> (DE - Techno / Chiptune) : 
        <a class="text-green-400" href="https://triac8bit.bandcamp.com/">Écouter sur Bandcamp</a><br />
        - <b>Cyanide Dansen</b> (FR - Dance / Chiptune) : 
        <a class="text-green-400" href="https://cyanidedansen.bandcamp.com/">Écouter sur Bandcamp</a><br />
        - <b>Please Lose Battle</b> (FR - Math-rock / Chiptune) : 
        <a class="text-green-400" href="https://pleaselosebattle.bandcamp.com/">Écouter sur Bandcamp</a><br />
        - <b>Breakbeat Heartbeat</b> (IR - Lofi, breakbeat / Chiptune) : 
        <a class="text-green-400" href="https://breakbeatheartbeat.bandcamp.com/album/better-lost">Écouter sur Bandcamp</a>
      </p>
    </div>
  </div>

</template>
<script>
import SectionTitle from '../common/SectionTitle'
import SubTitle from '../common/SubTitle'


export default {
  components: { SectionTitle, SubTitle },
  name: 'ChiptuneTuto',
  data: function () {
    return { highlight: {} }
  }
}
</script>