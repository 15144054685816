<template>
<div v-if="release" class="bg w-full fixed z-0 w-screen h-screen" v-bind:style="{ backgroundImage: 'url(' + release.img  + ')' }">
</div>
<div v-if="release" class="main relative bg-green-400 text-white my-8">
    <div class="cover">
      <img :src="release.img" class="w-full"/>
      <div class="bg-green-400 w-full p-2 relative">
          <div class="z-20 relative">
            <p class="font-semibold">Grizzly Cogs - {{ release.name }}</p>
            <p class="">Listen to the brand new {{ release.type }}</p>
          </div>
          <div class="z-10 arrow absolute bg-green-400"></div>
      </div>
      <div class="bg-white text-green-400 w-full links">
        <div v-for="link in release.links" :key="link.url">
          <ShortcutLink  :link="link" ></ShortcutLink>
          <hr />
        </div>
      </div>
      <div class="bg-green-400 w-full p-4 font-bold">
        <router-link to="/" v-on:click="goToWebsite()" >
          Visit Official website 
          <i class="fas fa-arrow-right"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import ShortcutLink from './ShortcutLink'

export default {
  name: 'ShortcutPage',
  components: {
    ShortcutLink
  },
  props: ['release'],
  mounted: function() {
    this.$store.commit('hideMenu');
  },
  methods: {
    goToWebsite: function() {
      this.$store.commit('showMenu');
    }
  }
}
</script>
<style scoped>

  .bgtop {
    opacity: 0.2;
  }
  
  .main {
    width: 80%;
    left: 10%;
  }

  .bg {
    background-size: cover;
    transform: scale(1); 
  }

  .bg::before {
    content: "";
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(20px);
}

  .arrow {
    bottom: -8px;
    left: 47%;
    width: 10%;
    height: 50%;
    padding: 0;
    margin: 0;
    transform: rotate(45deg);
  }

  @media (min-width: 764px) {
    .main {
      width: 60%;
      left: 20%;
    }
  }

  @media (min-width: 1024px) {
    .main {
      width: 30%;
      left: 35%;
    }
  }

  @media (min-width: 1536px) {
    .main {
      width: 20%;
      left: 40%;
    }
  }
</style>