<template>
  <div class="h-screen">
    <div class="lg:mt-24"></div>
    <SectionTitle name="Tutorials" />
    <div class="lg:mx-64 mx-4 text-left">
      <p class="mb-4">
        <img src="/img/flag-uk.png" class="w-6 inline" />
        From time to time, I do some workshop, explaining chiptune or video games creation. They are mainly in French though, sorry!
      </p>
      <p class="mb-4">
        <img src="/img/flag-fr.png" class="w-6 inline" />
        De temps en temps, j'anime des ateliers, pour permettre aux enfants ou aux plus grands de faire leurs premiers pas dans le 
        monde de la chiptune ou du jeu vidéo. Ici, vous trouverez les tutoriels/cours que j'ai réalisés. Ils sont en accès libres et gratuits, 
        en espérant que ça puisse vous aider :)
      </p>
      <SubTitle name="TUTORIELS" />
      <p class="mb-4">
      <ul>
        <li>
          <span class="font-bold">- Introduction à la chiptune avec GB Studio : </span>
          <span class="lg:hidden"><br /></span>
          <span class="text-green-400 font-bold">
            <a href="/tuto/chiptune"><i class="fa-solid fa-globe px-2"></i>WEB</a> |
            <a href="/pdf/chiptune.pdf"><i class="fa-regular fa-file px-2"></i>PDF</a>
          </span><br />
          <i>Un tutoriel qui permet d'apprendre à composer de la musique chiptune / 8-bit, avec une introduction à la théorie et à la composition musicale. Le tout sur GB Studio, un logiciel libre et gratuit permettant de créer des jeux vidéo de Game Boy</i>
        </li>
        <li>
          <span class="font-bold">- Mon premier jeu vidéo avec Bitsy : </span>
          <span class="lg:hidden"><br /></span>
          <span class="text-green-400 font-bold">
            <a href="/tuto/bitsy"><i class="fa-solid fa-globe px-2"></i>WEB</a> |
            <a href="/pdf/bitsy.pdf"><i class="fa-regular fa-file px-2"></i>PDF</a>
          </span><br />
          <i>Un tutoriel pour apprendre à créer son premier jeux vidéo avec Bitsy, un petit logiciel simple qui permet de créer des
            jeux vidéo directement dans le navigateur.</i>
        </li>
      </ul>
      </p>
      <p class="mt-4 italic">
        If you are interested in translating these tutorials in english or other languages, please 
        <a href="/contact" class="text-green-400">contact me</a>! 
      </p>
    </div>
  </div>

</template>
<script>
import SectionTitle from '../common/SectionTitle'
import SubTitle from '../common/SubTitle'


export default {
  components: { SectionTitle, SubTitle },
  name: 'Tuto',
  data: function () {
    return { highlight: {} }
  }
}
</script>