<template>
    <div class="lg:mt-28"></div>
    <div class="flex justify-center">
      <div class="w-1/3 m-4">
        <SectionTitle id="shows" name="RUN! RUN! JUMP! PUNCH!" />
      </div>
    </div>
    <div class="mx-16 xl:mx-48">
      <Carousel :gallery="gallery()" timer="3000" height="500px" />
    </div>
    <div class="mx-16 xl:mx-48 p-4">
      <p class="lg:text-justify text-lg">
        <strong class="text-green-400">Run! Run! Jump! Punch!</strong> is a Chiptune / Punk rock band from Lille, France in which I play Synth, 
        and drum-programming.
        <br /><br />
        Born from a collision between Punk-rock and lo-fi/chiptune electronic music, Run! Run! Jump! Punch! delivers happiness where soft synth 
        melodies melt with catchy and energetic saturated guitars. Add a pinch of football-like chorus voices, and you will get all the ingredients 
        of their music, to listen to on records or to sweat in live !
        <br /><br />
      </p>
      <SubTitle name="RELEASES" />
      <Loader :loaded="loaded" />
      <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 ">
        <Album v-for="album in albums" :key="album.name" :albumData="album" :isRRJP="true"/>
      </div>
    </div>
</template>
<script>
import SubTitle from '../common/SubTitle'
import Loader from '../common/Loader'
import axios from 'axios'
import Album from '../music/Album'
import Carousel from '../common/Carousel'
import SectionTitle from '../common/SectionTitle'

export default {
  name: "RunRunJumpPunch",
  components: { SubTitle, Loader, Album, Carousel, SectionTitle },
  data: function() {
    return { albums: [], loaded: false }
  },
  methods: {
    gallery: function() {
      return [
        { id: 0, src: "img/rrjp2.jpg", credit: "Loïse Doyen", link: "https://loisedoyen.com/" },
        { id: 1, src: "img/rrjp3.jpg", credit: "Loïse Doyen", link: "https://loisedoyen.com/" },
      ]
    }
  },
  mounted: function() {
      axios
        .get('/files/rrjp-albums.json')
        .then(response => { 
          this.albums = response.data.sort((a, b) => a.releaseDate > b.releaseDate ? -1 : 1)
          this.loaded = true
        })
  }
}
</script>