<template>
    <div class="bg-blue-900 w-full">
      <div class="fixed bg-white rounded-lg top-0 left-0 p-2 m-1 flex">
        <img class="w-8 pr-1 cursor-pointer" src="/img/flag-fr.png" v-on:click="switchTo('FR')" />
        <img class="w-8 pr-1 cursor-pointer" src="/img/flag-uk.png" v-on:click="switchTo('EN')" />
      </div>
      <div class="w-1/4 m-auto p-2">
        <a href="/"><img alt="Grizzly Cogs" src="../../assets/logo.png" class="w-full"></a>
      </div>
      <div class="m-auto text-white text-2xl font-bold pb-4">
        {{ epkData.title }}
      </div>
    </div>
    <div class="lg:flex text-blue-900 py-2 px-4">
      <div class="flex-1">
        <span class="font-bold">{{ epkData.styles }}</span>
        <div class="text-left py-4">
          <span v-html="epkData.bio" /><br /><br />
          <span v-html="epkData.actu" />
        </div>
        <span class="font-bold">[ {{ epkData.ffo }} ]</span>
        <div class="py-4 text-left font-bold">
          <span class="text-2xl">{{ epkData. linkHeader }}</span><br />
          <a href="https://www.youtube.com/watch?v=OWqVWSH8xnc">➡ Youtube</a><br />
          <a href="https://open.spotify.com/artist/6DYBO57C80qqR0VvCWLwCl?si=0iSCqj7fQNuBKHh7TbMh5A">➡ Spotify</a><br />
          <a href="https://www.deezer.com/fr/artist/9659080">➡ Deezer</a><br />
          <a href="https://grizzlycogs.bandcamp.com/">➡ Bandcamp</a><br />
          <a href="https://www.instagram.com/grizzlycogs/">➡ Instagram</a><br />
          <a href="https://www.facebook.com/GrizzlyCogs">➡ Facebook</a>
        </div>
      </div>
      <div class="flex-1">
        <iframe style="border: 0; width: 100%; height: 120px;" src="https://bandcamp.com/EmbeddedPlayer/album=2181734024/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/" seamless><a href="https://grizzlycogs.bandcamp.com/album/hibernation">Hibernation by Grizzly Cogs</a></iframe>
        <div class="text-left py-4">
          ➡ Contact : <a href="mailto:grizzlycogs@gmail.com" class="font-bold">grizzlycogs@gmail.com</a><br />
          ➡ Website : <a href="/" class="font-bold">www.grizzlycogs.com</a>
        </div>
        <div class="lg:w-3/4 w-full">
          <img src="/img/hello2.jpeg" />
        </div>
      </div>
    </div>
    <div class="lg:flex text-white font-bold text-xl pb-2">
      <a class="w-1/6" href="https://drive.google.com/file/d/1YItsX0qN7GuYK-BAbce7MAh6B7pjFsP6/view?usp=sharing">
        <div class="rounded-lg lg:mx-4 mx-2 bg-blue-900 p-2 lg:w-full">LOGO</div>
      </a>
      <a class="w-1/6 ml-4" href="https://drive.google.com/drive/folders/1_-45i67prN0ui3ztC0Ac4wIpF5QolQ1h?usp=sharing">
        <div class="rounded-lg lg:mx-4 mx-2 bg-blue-900 p-2 lg:w-full">AUDIO MP3</div>
      </a>
      <a class="w-1/6 ml-4" href="https://drive.google.com/drive/folders/118AQ13uzQ7LBXy6lQMxccS279aEb-wEf?usp=sharing">
        <div class="rounded-lg lg:mx-4 mx-2 bg-blue-900 p-2 lg:w-full">HD PICTURES</div>
      </a>
      <a class="w-1/6 ml-4" href="https://drive.google.com/file/d/1TZDlmydEa39-vgmtMkW8A0zSQdRhuguA/view?usp=sharing">
        <div class="rounded-lg lg:mx-4 mx-2 bg-blue-900 p-2 lg:w-full">TECHNICAL RIDER</div>
      </a>
      <a class="w-1/6 ml-4" href="https://drive.google.com/drive/folders/1EWHRfKFl4NDhwsy90erEs_gJPcdvcYVn?usp=sharing">
        <div class="rounded-lg lg:mx-4 mx-2 bg-blue-900 p-2 lg:w-full">FULL PRESSKIT</div>
      </a>
    </div>
</template>
<script>
  export default {
    data: function () {
      return { epkData: {}};
    },
    name: 'EPK',
    mounted: function() {
      this.$store.commit('hideMenu');
      this.epkData = {
        title: 'A party made of pixels',
        styles: 'ELECTRO | ELECTRONICA | CHIPTUNE | SYNTHWAVE',
        bio: 'Boris Warembourg, aka Grizzly Cogs, is an electronic and chiptune producer from Lille, France. He has produced 5 albums since 2016 played in various country including Denmark, Germany, Poland, UK, with artists such as C418 (Minecraft), Jukio Kalio (Fall Guys, Minit), Arnaud Rebotini or Jacques.<br /><br />Inspired by Justice, Slagmslaklubben, or Stranger Things soundtrack, his music is filled with pop and catchy melodies and a lot of nostagia, made with the sound of retro game consoles.<br />His brand new album “Hibernation” marks a new Grizzly Cogs milestone with 10 new tracks filled with synthwave / pop lullabies, made during the lockdown. Analog synths, emotional melodies, catchy hooks and nostalgic disco feels are included.<br />Grizzly Cogs shows can be seen as fireworks of powerful, energetic and nostalgic feelings which will surely make you dance !',
        actu: '➡ New album "Hibernation" out on Dec. 13th 2021 <br />➡ Single "Stellae", out on Jan 23rd 2022<br />➡ Available live (1 on stage)',
        ffo: 'ANAMANAGUCHI | CARPENTER BRUT | JUSTICE | DANGER',
        linkHeader: 'LINKS',
      }
    },
    methods: {
      switchTo(lang) {
        if (lang == 'FR') {
          this.epkData.bio = 'Boris Warembourg, de son nom de scène Grizzly Cogs, est un producteur de musique électronique et chiptune de Lille. Il a produit 5 albums depuis 2016 et joué dans plusieurs pays à travers le monde, comme le Danemark, l’Allemagne, la Pologne, l’Angleterre, la Belgique, avec des artistes comme C418 (Minecraft), Jukio Kalio (Fall Guys, Minit), Arnaud Rebotini ou encore Jacques.<br /><br />Inspiré par Justice, Slagsmalsklubben, ou la BO de Stranger Things, sa musique fourmille de mélodies pop et entrainantes, faites avec le son de consoles de jeux rétro.<br />Son nouvel album, “Hibernation”, marque un nouveau tournant dans sa discographie, avec 10 nouveaux morceaux aux mélodies pop / synthwave, écrits pendant le confinement. Synthé analogique, mélodies emo et hook entraînants sont de la partie.<br />Les concerts de Grizzly Cogs peuvent s’apparentent à un feu d’artifice d’émotions nostalgiques et d’énergies<br />positives, qui vous feront probablement hocher la tête !'
          this.epkData.actu = '➡ Nouvel album "Hibernation" sorti le 13 Décembre 2021 <br />➡ Single "Stellae", sorti le 23 Janvier 2022<br />➡ Dispo en live (1 sur scène)';
          this.epkData.linkHeader = 'LIENS';
        } else {
          this.epkData.bio = 'Boris Warembourg, aka Grizzly Cogs, is an electronic and chiptune producer from Lille, France. He has produced 5 albums since 2016 played in various country including Denmark, Germany, Poland, UK, with artists such as C418 (Minecraft), Jukio Kalio (Fall Guys, Minit), Arnaud Rebotini or Jacques.<br /><br />Inspired by Justice, Slagmslaklubben, or Stranger Things soundtrack, his music is filled with pop and catchy melodies and a lot of nostagia, made with the sound of retro game consoles.<br />His brand new album “Hibernation” marks a new Grizzly Cogs milestone with 10 new tracks filled with synthwave / pop lullabies, made during the lockdown. Analog synths, emotional melodies, catchy hooks and nostalgic disco feels are included.<br />Grizzly Cogs shows can be seen as fireworks of powerful, energetic and nostalgic feelings which will surely make you dance !'
          this.epkData.actu = '➡ New album "Hibernation" out on Dec. 13th 2021 <br />➡ Single "Stellae", out on Jan 23rd 2022<br />➡ Available live (1 on stage)';
          this.epkData.linkHeader = 'LINKS';
        }
      }
    }
  }
</script>