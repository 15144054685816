<template>
<div>
  <Loader :loaded="loaded" />
  <div v-if="loaded" class="hidden lg:flex lg:mx-8 my-1">
    <div class="mx-2 pt-1 font-bold text-red-800">
      Display : 
    </div>
    <div v-bind:class="{ 'bg-green-400': !ostFilter && !devFilter && yearFilter.length == 0}" 
      class="rounded-full bg-gray-400 text-white px-4 py-1 cursor-pointer font-medium" v-on:click="filter('ALL')">
      <span v-if="!ostFilter && !devFilter && yearFilter.length == 0">
        <i class="fas fa-check"></i>
      </span>
      All
    </div>
    <div v-bind:class="{ 'bg-green-400': ostFilter}" 
      class="rounded-full ml-1 bg-yellow-500 text-white px-4 py-1 cursor-pointer font-medium" v-on:click="filter('OST')">
      <span v-if="ostFilter">
        <i class="fas fa-check"></i>
      </span>
      soundtrack
    </div>
    <div v-bind:class="{ 'bg-green-400': devFilter}" 
      class="rounded-full ml-1 bg-purple-500 text-white px-4 py-1 cursor-pointer font-medium" v-on:click="filter('DEV')">
      <span v-if="devFilter">
        <i class="fas fa-check"></i>
      </span>
      dev
    </div>
    <div class="rounded-full ml-1 bg-red-500 text-white px-4 py-1 cursor-pointer font-medium" v-on:click="filter(2024)">
      <span v-if="yearFilter.includes(2024)">
        <i class="fas fa-check"></i>
      </span>
      2024
    </div>
    <div class="rounded-full ml-1 bg-red-500 text-white px-4 py-1 cursor-pointer font-medium" v-on:click="filter(2023)">
      <span v-if="yearFilter.includes(2023)">
        <i class="fas fa-check"></i>
      </span>
      2023
    </div>
    <div class="rounded-full ml-1 bg-red-500 text-white px-4 py-1 cursor-pointer font-medium" v-on:click="filter(2022)">
      <span v-if="yearFilter.includes(2022)">
        <i class="fas fa-check"></i>
      </span>
      2022
    </div>
    <div class="rounded-full ml-1 bg-red-500 text-white px-4 py-1 cursor-pointer font-medium" v-on:click="filter(2021)">
      <span v-if="yearFilter.includes(2021)">
        <i class="fas fa-check"></i>
      </span>
      2021
    </div>
    <div class="rounded-full ml-1 bg-red-500 text-white px-4 py-1 cursor-pointer font-medium" v-on:click="filter(2020)">
      <span v-if="yearFilter.includes(2020)">
        <i class="fas fa-check"></i>
      </span>
      2020
    </div>
    <div class="rounded-full ml-1 bg-red-500 text-white px-4 py-1 cursor-pointer font-medium" v-on:click="filter(2019)">
      <span v-if="yearFilter.includes(2019)">
        <i class="fas fa-check"></i>
      </span>
      2019
    </div>
    <div class="rounded-full ml-1 bg-red-500 text-white px-4 py-1 cursor-pointer font-medium" v-on:click="filter(2018)">
      <span v-if="yearFilter.includes(2018)">
        <i class="fas fa-check"></i>
      </span>
      2018
    </div>
    <div class="rounded-full ml-1 bg-red-500 text-white px-4 py-1 cursor-pointer font-medium" v-on:click="filter(2017)">
      <span v-if="yearFilter.includes(2017)">
        <i class="fas fa-check"></i>
      </span>
      2017
    </div>
  </div>
  <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 lg:mx-8 my-1 ">
        <Game :game="game" v-for="game in filteredGames" :key="game.id" />
  </div>
</div>
</template>
<script>
import Loader from '../common/Loader'
import Game from '../game/Game'
import axios from 'axios'

export default {
  components: { Loader, Game },
  name: "GameList",
  data: function() {
    return { games: [], filteredGames: [], filtered: false, ostFilter: false, devFilter: false, yearFilter: [], loaded: false }
  },
  mounted: function() {
    axios
      .get('/files/games.json')
      .then(response => { 
        this.games = response.data.sort((a,b) => { return a.releaseDate < b.releaseDate ? 1 : -1})
        this.loaded = true
        for(var i = 0; i < this.games.length; i++) {
          this.games[i].id = i;
        }
        this.filteredGames = this.games;
      });
  },
  methods: {
    getDelay: function(game) {
      if (this.filtered)
        return 0;
      return game.id * 100;
    },
    addInFilter: function(type) {
      var typedGames = [];
      if (type == 'OST') {
        typedGames = this.games.filter(game => game.work && game.work.includes('OST'));
      } else if (type == 'DEV') {
        typedGames = this.games.filter(game => game.work && game.work.includes('DEV'));
      } else {
        typedGames = this.games.filter(game => game.year == type);
      }
      for (var i = 0; i < typedGames.length; i++) {
        if (!this.filteredGames.find(fg => fg.id == typedGames[i].id)) {
          this.filteredGames.push(typedGames[i])
        }
      }
    },
    filter: function(type) {
      this.filtered = true;
      if (type == 'ALL') {
        this.ostFilter = false;
        this.devFilter = false;
        this.yearFilter = [];
        this.filteredGames = this.games;
      }
      else if (type == 'OST') {
        this.ostFilter = !this.ostFilter;
      }
      else if (type == 'DEV') {
        this.devFilter = !this.devFilter;
      }
      else {
        if (this.yearFilter.includes(type)) {
          this.yearFilter = this.yearFilter.filter(f => f != type);
        } else {
          this.yearFilter.push(type);
        }
      }
      
      this.filteredGames = [];
      if (!this.ostFilter && !this.devFilter)
        this.filteredGames = this.games;
      if (this.ostFilter)
        this.addInFilter('OST');
      if (this.devFilter) 
        this.addInFilter('DEV');
      if (this.yearFilter.length > 0)
        this.filteredGames = this.filteredGames.filter(game => this.yearFilter.find(y => y == game.year) != null);
    }
  }
}
</script>
