<template>
  <div class="hello relative">
    <div class="absolute blur w-full h-full"></div>
    <div class="absolute z-10 top-1/3 lg:top-1/2 flex justify-center w-full">
      <div class="text-white lg:text-4xl text-3xl opacity-60 font-extrabold">
        Need some sounds for your game project ?
        <br />Organizing a Grizzly Cogs show ?
        <br />Let's talk !
      </div>
    </div>
  </div>
  <SectionTitle name="ABOUT" />
  <div class="min-h-screen">
    <div class="lg:flex justify-center lg:mx-0 mx-8" >
      <div class="lg:w-1/3 text-justify p-4">
        Boris Warembourg, aka Grizzly Cogs, is an electronic and chiptune producer from Lille, France. He has produced 5 albums since 2016 and played 30+ shows in various country including Denmark, Germany, Poland, UK, with artists such as C418 (Minecraft), Jukio Kalio (Fall Guys, Minit), Arnaud Rebotini or Jacques.
        <br /><br />
        Inspired by Justice, Slagmslaklubben or Stranger Things soundtrack, his music is filled with pop and catchy melodies and a lot of nostagia, made with the sound of retro game consoles and analog synths.
        <br />
        His brand new album “Hibernation” marks a new Grizzly Cogs milestone with 10 new tracks filled with  synthwave / pop lullabies, made during the lockdown. Analog synths, emotional melodies, catchy hooks and nostalgic disco feels are included. 
      </div>
      <div class="lg:w-1/3 text-justify p-4">
        Boris also wrote soundtracks and sound designed for 10+ indie games, including Ludum Dare #42's winner "Dark Soil", or french cartoonist Petit Pied's games "La vie" and "Simulateur de vie".
        <br />
        He has been invited at A.MAZE (Berlin, DE), an European Indie Game Event to talk about his vision of sound design and about to create meanful sounds in game jam situations.
        <br /><br />
        Since 2020, He also developped his own tiny free games, such as "Ice Cream 4ever" (#3 audio for GBJAM #8) or "I Just Want To Survive". 
        His main goals as game developer are to provide fun, tiny and free games for everyone.
      </div>
    </div>
    <SectionTitle name="FOLLOW ME" />
    <div class="text-center text-3xl text-yellow-800">
      <a href="http://grizzlycogs.bandcamp.com" target="_blank"><i class="fab fa-bandcamp text-green-400"></i></a>
      <a href="https://open.spotify.com/artist/6DYBO57C80qqR0VvCWLwCl?si=n13UY0f_T6OFQw-lRJm5Iw" target="_blank"><i class="fab fa-spotify text-green-400"></i></a>
      <a href="https://www.instagram.com/grizzlycogs/" target="_blank"><i class="fab fa-instagram text-green-400"></i></a>
      <a href="https://twitter.com/GrizzlyCogs" target="_blank"><i class="fab fa-twitter text-green-400"></i></a>
      <a href="https://www.facebook.com/GrizzlyCogs/" target="_blank"><i class="fab fa-facebook text-green-400"></i></a>
      <br /><br />
    </div>
    <SectionTitle name="SAY HELLO" />
    <div class="py-4 bg-green-100 rounded-lg lg:mx-48">
      <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value="contact" />
        <div class="m-4">
          <input type="text" name="subject" class="lg:w-96 w-64 h-8 rounded p-2" placeholder="Subject"/>
        </div>
        <div class="m-4">
          <input type="mail" name="mail" class="lg:w-96 w-64 h-8 rounded p-2" placeholder="Your e-mail" />
        </div>
        <div class="m-4">
          <textarea name="body" class="lg:w-96 w-64 h-36 rounded p-2" placeholder="Your message"></textarea>
        </div>
        <button type="submit" class="rounded-full bg-green-400 px-12 py-2 text-xl text-white font-bold">Send !</button>
      </form>
    </div>
    <SectionTitle name="PRESS KITS" />
    <div class="lg:mx-48">
      <span class="text-green-400 font-bold">
        <a href="https://drive.google.com/drive/folders/1EWHRfKFl4NDhwsy90erEs_gJPcdvcYVn?usp=sharing">
          <i class="fas fa-folder"></i> Grizzly Cogs Press kit
        </a>
        <br />
        <a href="https://drive.google.com/drive/folders/1scnuwHejPHDdmjZDNZOYeobVgqyr6fqK?usp=sharing">
          <i class="fas fa-folder"></i> Run! Run! Jump! Punch! Press kit
        </a>
      </span>
    </div>
    <SectionTitle name="CREDITS" />
    <div>
      <strong class="text-green-400">Logo, Clouds and colors :</strong> 
      <a href="https://twitter.com/NoNameNoSocks" target="_blank">NoNameNoSocks</a>
      <br />
      <strong class="text-green-400">Web design and dev :</strong> Grizzly Cogs
      <br />
      <strong class="text-green-400">Photographs :</strong>
      <a href="https://www.loisedoyen.com" target="_blank">Loïse Doyen</a> &
      <a href="https://www.chiptography.com" target="_blank">Chiptography</a>
      <br />
      <i>Website made with Netlify, FaunaDB, VueJS, tailwindcss.</i>
    </div>
  </div>
</template>
<script>
import SectionTitle from '../common/SectionTitle.vue'

export default {
  name: "Contact",
  components: { SectionTitle }
}
</script>
<style scoped>
a {
  margin: 5px;
}

.hello {
  background-image: url("/img/contactme.jpg");
  background-size: cover;
  background-position: 0% 25%;
  height: 40rem;  
}

  .blur {
    backdrop-filter: blur(10px);
  }
</style>