<template>
  <div class="fixed top-48 bg-green-400 text-white p-4 text-left invisible lg:visible">
    <b>Sommaire</b><br />
    <a href="#1">1. Présentation générale</a><br />
    <a href="#2">2. Dessiner les différents éléments du jeu</a><br />
    <a href="#3">3. Fabrication du labyrinthe</a><br />
    <a href="#4">4. Sauvegarder ou télécharger son jeu</a><br />
    <a href="#5">5. Pour aller plus loin</a><br />
  </div>
  <div class="">
    <div class="lg:mt-24"></div>
    <p class="lg:mx-96 mx-4 text-yellow-800 font-bold mb-4 text-left">
      <a href="/tuto">
        <i class="fa-solid fa-arrow-left"></i> Retour aux tutoriels
      </a>
    </p>
  </div>
  <SectionTitle name="Mon premier jeu vidéo avec Bitsy" />
  <div class="lg:mx-96 mx-4 text-left">
    <!-- PART 1 PRESENTATION -->
    <p id="1" class="text-green-400 font-bold text-xl mb-4">1. Présentation générale</p>
    <p class="my-4 text-justify">
      Nous allons créer notre premier jeu, qui sera un labyrinthe, dans lequel le joueur devra trouver une sortie.
    </p>
    <center><img src="/tuto/bitsy1.png" class="lg:w-2/3"/></center>
    <p class="my-4 text-justify">
      Nous allons utiliser Bitsy, qui permet de faire des petits jeux vidéo. Va sur 
      <a href="https://make.bitsy.org/" class="text-green-400 font-bold">make.bitsy.org</a>
    </p>
    <p class="my-4 text-justify">
      Bitsy se compose de 3 parties principales :<br />
      <b>- Room :</b> le niveau du jeu. C’est ici que l’on pourra fabriquer le niveau du jeu<br />
      <b>- Dessin :</b> C’est ici qu’on pourra dessiner notre personnage et tous les autres composants de notre jeu.<br />
      <b>- Couleurs :</b> On pourra choisir ici les couleurs de notre jeu
    </p>
    
    <!-- PART 1 DESSINS -->
    <p id="2" class="text-green-400 font-bold text-xl mb-4">2. Dessiner les différents éléments du jeu</p>
    <p class="my-4 text-justify">
      Dans la partie <b>“dessin”</b>, on va pouvoir dessiner les différents éléments du jeu. Ce seront les
      différents éléments de ton jeu. Clique sur un pixel pour changer sa couleur. La partie couleur
      te permet de changer la couleur de tes dessins.
    </p>
    
    <SubTitle name="Le personnage principal (Avatar)" />
    <p class="my-4 text-justify">
      Aller dans la partie <b>“avatar”</b> de la fenêtre <b>“dessin”</b>. <br />
      Le personnage principal sera le personnage que l’on veut contrôler. 
      Par défaut, c’est un bonhomme. Mais tu peux lui donner l’apparence que tu veux. Laisse aller ton imagination !<br />
      Quelques exemples :
    </p>
    <center><img src="/tuto/bitsy2.png" class="lg:w-2/3"/></center>

    <SubTitle name="La sortie" />
    <p class="my-4 text-justify">
      Aller dans la partie <b>“item”</b> de la fenêtre <b>“dessin”</b><br />
      Quand notre personnage aura rejoint la sortie, il aura gagné ! Il faut donc créer une sortie
      qui soit bien visible pour que le joueur voit où il faut aller.<br />
      Quelques exemples :
    </p>
    <center><img src="/tuto/bitsy3.png" class="lg:w-2/3"/></center>

    <SubTitle name="Les murs" />
    <div class="lg:flex my-4">
      <div>
        <center><img src="/tuto/bitsy4.png" class="w-2/3 lg:w-full"/></center>
      </div>
      <div>
        <p class="lg:ml-4 text-justify">
          Aller dans la partie <b>“tile”</b> de la fenêtre <b>“dessin”</b><r />
          Les murs vont nous permettre de dessiner les différents chemins de notre labyrinthe.<br />
          Tu peux t’aider de l’écran <b>“room”</b> pour voir à quoi ressemblerait tes murs dans le jeu.<br />
          Quelques exemples :
        </p>
        <center><img src="/tuto/bitsy5.png" class="lg:w-2/3"/></center>
      </div>
    </div>

    <!-- PART 3 LABYRINTHE -->
    <p id="3" class="text-green-400 font-bold text-xl mb-4">3. Fabrication du labyrinthe</p>
    <SubTitle name="Donner un nom au jeu" />
    <p class="my-4 text-justify">
      Il faut d’abord choisir le nom de ton jeu. Tout en haut de l’écran, tu peux remplacer le texte
      <b>“Écris le nom de ton jeu”</b>, par le nom de ton jeu.
    </p>

    <SubTitle name="La création du niveau" />
    <p class="my-4 text-justify">
      Une fois tous les éléments dessinés, c’est l’heure de fabriquer notre labyrinthe !<br />
      Assure toi que dans la partie <b>“dessin”</b>, tu es sur l’onglet <b>“tile”</b>, de façon à ce que ton mur soit visible.<br />
      Ensuite, dans la partie <b>“mur”</b> clique sur la case de ton choix pour ajouter ou supprimer un mur. 
      Tu vas ainsi pouvoir créer des chemins pour ton personnage.<br />
      - Pour changer la position de ton personnage, va dans <b>“dessin”</b> puis <b>”avatar”</b>, puis clique sur la case où 
      tu veux que ton personnage commence le jeu.<br />
      - Pour placer ta sortie, va dans <b>“dessin”</b> puis <b>“item”</b> et clique sur la case où tu veux placer
      ta sortie.<br />
      Exemple :
    </p>
    <center><img src="/tuto/bitsy6.png" class="lg:w-1/2 w-2/3 mb-4"/></center>

    <SubTitle name="Ne pas passer à travers les murs" />
    <div class="lg:flex my-4">
      <div>
        <p class="lg:mr-4 text-justify">
          En appuyant sur <b>“Jouer”</b> en haut à droite, tu peux tester ton jeu.
          Tu peux déplacer ton personnage en appuyant sur les flèches.
          Cependant, ton personnage peut traverser les murs !<br /><br />
          Pour régler ce problème, va dans <b>“dessin”</b> puis <b>“tile”</b>. Puis
          clique sur <b>“mur”</b>
          Si tu relance le jeu (en cliquant sur <b>“Jouer”</b> en haut à droite de
          l’écran), tu verras que ton personnage ne peut plus traverser
          les murs.
        </p>
      </div>
      <div class="lg:w-1/3">
        <center><img src="/tuto/bitsy7.png" class="w-2/3 lg:w-full"/></center>
      </div>
    </div>

    <SubTitle name="La sortie" />
    <div class="lg:flex my-4">
      <div class="lg:w-1/3">
        <center><img src="/tuto/bitsy8.png" class="w-2/3 lg:w-full"/></center>
      </div>
      <div>
        <p class="lg:ml-4 text-justify">
          Si le joueur a atteint la sortie, il faut lui dire qu’il a gagné ! Pour
          cela, aller dans <b>“dessin”</b> puis <b>“item”</b>. En dessous du dessin de
          votre sortie, il y a un <b>“dialog”</b>.<br /><br />
          On peut remplacer le texte par une phrase qui indique qu’il a
          gagné, par exemple <b>“Bravo ! Vous avez trouvé la sortie !”</b>.
          <br /><br />
          Si tu relance le jeu (en cliquant sur <b>“Jouer”</b> en haut à droite de
          l’écran), lorsque le joueur arrivera à la sortie, ce message sera
          affiché (voir ci-contre)
        </p>
      </div>
    </div>

    <!-- PART 4 SAUVEGARDER -->
    <p id="4" class="text-green-400 font-bold text-xl mb-4">4. Sauvegarder ou télécharger son jeu</p>
    <p class="my-4 text-justify">
      Et voilà ! Ton jeu est terminé ! Félicitations !<br />
      Si tu veux le télécharger, clique sur <b>“outil”</b> (en haut à droite), puis sur <b>“télécharger”</b>. Dans
      la partie <b>“télécharger”</b> qui s’affiche, clique sur <b>“télécharger le jeu”</b>. Tu pourras ensuite y jouer 
      sur ton ordinateur !
    </p>
    <p class="my-4 text-justify">
      Si tu veux continuer à travailler sur ton jeu plus tard, tu pourras le <b>charger</b>.
      Va sur le site <b>make.bitsy.org</b>, puis clique sur <b>“outil”</b> (en haut à droite), puis sur
      <b>“télécharger”</b>. Dans la partie <b>“télécharger”</b> qui s’affiche, clique sur <b>“charger un jeu”</b> et
      sélectionne le fichier que tu as téléchargé plus tôt.
    </p>

    <!-- PART 5 BONUS --> 
    <p id="5" class="text-green-400 font-bold text-xl mb-4">5. Pour aller plus loin</p>
    <SubTitle name="Plus de niveaux" />
    <p class="my-4 text-justify">
      Tu peux ajouter autant de niveaux que tu le souhaite !
    </p>
    <p class="my-4 text-justify">
      Pour cela, clique sur le petit <b>“+”</b> dans la partie <b>“room”</b>, et confectionne un nouveau niveau.
    </p>
    <p class="my-4 text-justify">
      Quand c’est fait, clique sur <b>“outil”</b> (en haut à droite), puis sur <b>“sorties & fins”</b>. Dans la partie 
      <b>“sorties & fins”</b> qui s’affiche, clique sur le bouton <b>“bouger”</b> de la partie gauche, puis sélectionne 
      la case où se trouve la sortie de la première salle.
    </p>
    <p class="my-4 text-justify">
      Ensuite, sur la partie droite, clique sur <b>le petit crayon</b>. Sélectionne ton nouveau niveau. Puis clique
      sur <b>“bouger”</b> pour sélectionner l’endroit où le joueur sera placé dans le nouveau niveau,
      lorsqu’il aura trouvé la porte du premier niveau.
    </p>
    <center><img src="/tuto/bitsy9.png" class="lg:w-1/3 w-2/3"/></center>

    <SubTitle name="Des animations" />
    <center><img src="/tuto/bitsy10.png" class="lg:w-1/3 w-2/3"/></center>
    <p class="my-4 text-justify">
      Tu peux faire bouger et donner de la vie à ton personnage ou à des objets en créant des animations !<br />
      Pour cela, dans la partie <b>“dessin”</b>, clique sur <b>“avatar”</b> ou sur <b>“item”</b>, puis clique sur
      <b>“animation”</b>, en bas. Clique ensuite sur <b>“calque 2”</b>, et dessine la deuxième image de ton
      personnage ou de ton objet. Il sera alors “animé”, cette animation sera visible en jeu.
    </p>

    <SubTitle name="Des personnages et des dialogues" />
    <div class="lg:flex my-4">
      <div class="lg:w-2/3">
        <center><img src="/tuto/bitsy11.png" class="w-2/3 lg:w-full"/></center>
      </div>
      <div>
        <p class="lg:ml-4 text-justify">
          Tu peux créer des nouveaux personnages dans la partie <b>“dessin”</b>, en cliquant sur <b>sprite</b>, puis sur <b>“+”</b>.
          Tu peux alors dessiner un nouveau personnage.<br /><br />
          En allant dans la partie <b>“dialogue”</b>, tu peux cliquer sur <b>“+”</b> pour ajouter un nouveau dialogue. Imagine
          une phrase que ton personnage pourrait dire. Si tu veux lui faire dire plusieurs phrases, tu peux en ajouter plusieurs 
          à la suite en cliquant sur <b>“ajouter”</b>, <b>“dialogue”</b>, puis <b>“+ dialogue”</b>.<br /><br />
          Il faut ensuite relier ton nouveau dialogue à ton nouveau personnage. Pour ce faire, va dans la partie <b>“dessin”</b>, 
          sous votre nouveau personnage, clique sur dialogue et ajoute le dialogue précédemment créé.
        </p>
      </div>
    </div>

    <SubTitle name="Une clé, une porte verrouillée" />
    <p class="my-4 text-justify">
      Tout d’abord, dessine une clé (Partie <b>“dessin”</b>, <b>“item”</b>, <b>“+”</b>), nommez le dessin “clé”.
      Ensuite, dessinez une porte avec une serrure (Partie <b>“dessin”</b>, <b>“sprite”</b>, <b>“+”</b>), nommez le
      dessin “porte”. Tu peux ensuite placer la porte à la place de la sortie que nous avons créé
      plus tôt.
    </p>
    <center><img src="/tuto/bitsy12.png" class="lg:w-1/4 w-2/3"/></center>
    <p class="my-4 text-justify">
      Ensuite, dans la partie <b>“Sortie & fin”</b>, clique sur la petite roue 
      <img class="inline w-4" src="/tuto/bitsy13.png" /> 
      en bas à droite, puis clique sur <b>“ajouter un verrou”</b>. La partie <b>“dialogue”</b> s’ouvre.<br />
      Changez les paramètres pour indiquer que si “<b>clé</b> dans l’inventaire >= 1”, la porte s’ouvre.
      Ainsi il sera possible pour le joueur de passer au niveau suivant seulement s’il a trouvé la clé
      dans le niveau.
    </p>
  </div>

</template>
<script>
import SectionTitle from '../common/SectionTitle'
import SubTitle from '../common/SubTitle'


export default {
  components: { SectionTitle, SubTitle },
  name: 'BitsyTuto',
  data: function () {
    return { highlight: {} }
  }
}
</script>